import { busy, notBusy } from '../busy/actions'
import { handleGetErrors, handleUpdateErrors } from '../error/actions'
import { getUserData } from '../common'
import { setSelectedItem } from '../selected/actions'
import redux from '../'
import {
  getEmailAddressComposeMode,
  getEmailSubjectComposeMode,
  isOutlookComposeMode,
  isOutlookReadMode,
  getEmailMetadataReadMode
} from '../../lib/office'
export const PROJECT_UPDATE_ACTION = 'PROJECT_UPDATE_ACTION'
export const PROJECT_DELETE_ACTION = 'PROJECT_DELETE_ACTION'

export const PROJECT_GET_PAGE_ITEMS_ACTION = 'PROJECT_GET_PAGE_ITEMS_ACTION'
export const PROJECT_GET_PAGE_ITEM_ACTION = 'PROJECT_GET_PAGE_ITEM_ACTION'
export const PROJECT_GET_PERSON_BY_EMAIL_ACTION = 'PROJECT_GET_PERSON_BY_EMAIL_ACTION'
export const PROJECT_SET_ORDER_ACTION = 'PROJECT_SET_ORDER_ACTION'
export const PROJECT_SET_LIMIT_ACTION = 'PROJECT_SET_LIMIT_ACTION'
export const PROJECT_SET_OFFSET_ACTION = 'PROJECT_SET_OFFSET_ACTION'
export const PROJECT_SET_SEARCH_ACTION = 'PROJECT_SET_SEARCH_ACTION'
export const PROJECT_TOGGLE_SHOW_FAVORITES = 'PROJECT_TOGGLE_SHOW_FAVORITES'
export const PROJECT_TOGGLE_SHOW_RECENT = 'PROJECT_TOGGLE_SHOW_RECENT'

export function projectUpdateAction (project) {
  return {
    type: PROJECT_UPDATE_ACTION,
    project
  }
}

export function projectDeleteAction (project) {
  return {
    type: PROJECT_DELETE_ACTION,
    project
  }
}

export function initProjectActions (api, store) {
  function getPageItems (id) {
    const state = store.getState()
    const {
      search,
      limit,
      offset,
      orderBy,
      order,
      showFavorites,
      showRecent
    } = state.project

    const sort = orderBy ? `${orderBy}.${order}` : null
    const params = {
      query: [],
      limit,
      offset,
      sort,
      recent: showRecent
    }

    if (typeof search === 'string' && search.length > 0) {
      const split = search.split(',').map(s => s.trim())
      if (split.length === 1) {
        if (split[0].length > 0) params.query.push({ key: 'name', value: split[0] })
      } else if (split.length === 2) {
        if (split[0].length > 0) params.query.push({ key: 'name', value: split[0] })
        if (split[1].length > 0) params.query.push({ key: 'collection_name', value: split[1] })
      }
    }

    if (showFavorites) {
      params.query.push({ key: 'is_favorite', value: showFavorites, equal: true })
    }
    return function (dispatch) {
      dispatch(busy())
      api.getProjects(params, getUserData(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: PROJECT_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value,
          totalCount: result.data.total_count
        })

        if (id) {
          getPageItem(id)(dispatch)
        }
      }).catch(handleGetErrors('project', dispatch))
    }
  }

  function getPageItem (id) {
    const state = store.getState()
    const { sharePointSaveEnabled } = state.login.userData

    const params = {
      query: [{ key: 'id', value: id, equal: true }],
      limit: 1
    }

    return function (dispatch) {
      dispatch(busy())
      api.getProjects(params, getUserData(store)).then(result => {
        dispatch(notBusy())
        if (Array.isArray(result.data.value) && result.data.value.length === 1) {
          dispatch({
            type: PROJECT_GET_PAGE_ITEM_ACTION,
            pageItem: result.data.value[0],
            totalCount: result.data.total_count
          })

          dispatch(setSelectedItem(result.data.value[0]))
          if (sharePointSaveEnabled) {
            dispatch(redux.actions.files.getRootFolder(result.data.value[0]))
          }
        }
      }).catch(handleGetErrors('project', dispatch))
    }
  }

  function getSuggestionItems () {
    const state = store.getState()
    const { sharePointSaveEnabled } = state.login.userData

    return async function (dispatch) {
      let email
      let subjectLine

      if (isOutlookReadMode()) {
        const metadata = getEmailMetadataReadMode()
        subjectLine = metadata.subject
        email = metadata.sender
      } else if (isOutlookComposeMode()) {
        subjectLine = await getEmailSubjectComposeMode()
        email = await getEmailAddressComposeMode()
      }
      email = email.toLowerCase()

      const pattern = /\(pds-p\d+\)/
      const match = subjectLine.match(pattern)
      if (match) {
        const id = String(match).match(/\d+/)
        const params = {
          query: [
            { key: 'id', value: id, equal: true }
          ],
          limit: 1
        }
        dispatch(busy())
        api.getProjects(params, getUserData(store)).then(result => {
          dispatch(notBusy())

          if (result.data.value.length > 0) {
            dispatch({
              type: PROJECT_GET_PAGE_ITEM_ACTION,
              pageItem: result.data.value[0],
              totalCount: result.data.total_count
            })
            dispatch(setSelectedItem(result.data.value[0]))
            if (sharePointSaveEnabled) {
              dispatch(redux.actions.files.getRootFolder(result.data.value[0]))
            }
          } else {
            getSuggestionItemsByAddress(email, sharePointSaveEnabled, dispatch)
          }
        }).catch(handleGetErrors('project', dispatch))
      } else {
        getSuggestionItemsByAddress(email, sharePointSaveEnabled, dispatch)
      }
    }
  }

  function toggleFavorite (project) {
    return function (dispatch) {
      const favorite = !project.is_favorite
      dispatch(busy())
      api.setProjectFavorite(project.id, favorite, getUserData(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('project', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: PROJECT_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: PROJECT_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: PROJECT_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: PROJECT_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Toggle show favorites
   */
  function toggleShowFavorites () {
    return {
      type: PROJECT_TOGGLE_SHOW_FAVORITES
    }
  }

  /**
   * Toggle show recent
   */
  function toggleShowRecent () {
    return {
      type: PROJECT_TOGGLE_SHOW_RECENT
    }
  }

  /**
   * Helpfuntion to manage suggestionItems.
   */
  function getSuggestionItemsByAddress (email, sharePointSaveEnabled, dispatch) {
    const params = {
      query: [
        { key: 'person_role.person_email', value: email, equal: true }
      ],
      recent: true,
      limit: 1
    }
    dispatch(busy())
    api.getProjects(params, getUserData(store)).then(result => {
      dispatch(notBusy())

      if (result.data.value.length > 0) {
        dispatch({
          type: PROJECT_GET_PAGE_ITEM_ACTION,
          pageItem: result.data.value[0],
          totalCount: result.data.total_count
        })
        dispatch(setSelectedItem(result.data.value[0]))
        if (sharePointSaveEnabled) {
          dispatch(redux.actions.files.getRootFolder(result.data.value[0]))
        }
      } else {
        const params = {
          query: [
            { key: 'person_role.person_email', value: email, equal: true }
          ],
          sort: 'updated_at.desc',
          limit: 1
        }
        dispatch(busy())
        api.getProjects(params, getUserData(store)).then(result => {
          dispatch(notBusy())

          if (result.data.value.length > 0) {
            dispatch({
              type: PROJECT_GET_PAGE_ITEM_ACTION,
              pageItem: result.data.value[0],
              totalCount: result.data.total_count
            })
            dispatch(setSelectedItem(result.data.value[0]))
            if (sharePointSaveEnabled) {
              dispatch(redux.actions.files.getRootFolder(result.data.value[0]))
            }
          }
        }).catch(handleGetErrors('project', dispatch))
      }
    }).catch(handleGetErrors('project', dispatch))
  }

  return {
    getPageItems,
    getSuggestionItems,
    getPageItem,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    toggleFavorite,
    toggleShowFavorites,
    toggleShowRecent
  }
}
