import { busy, notBusy } from '../busy/actions'
import { handleGetErrors } from '../error/actions'
import { getUserData } from '../common'

export const CONTENT_TYPE_GET_OK_ACTION = 'CONTENT_TYPE_GET_OK_ACTION'

export function initContentTypeActions (api, store) {
  function getContentTypes (uiContext) {
    return function (dispatch) {
      dispatch(busy())
      const query = [
        { key: 'ui_context', value: uiContext || 'contact', equal: true }
      ]
      api.getContentTypeUIFields({ query, sort: 'ui_order' }, getUserData(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedContentTypes(result.data.value))
      }).catch(handleGetErrors('contentType', dispatch))
    }
  }

  function receivedContentTypes (items) {
    return {
      type: CONTENT_TYPE_GET_OK_ACTION,
      items
    }
  }

  return { getContentTypes }
}
