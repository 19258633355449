import React from 'react'
import { SvgIcon } from '@material-ui/core'
import {
  ArrowDownward,
  ArrowUpward,
  ContactsOutlined,
  FileCopy,
  Help,
  Language,
  SettingsApplicationsOutlined
} from '@material-ui/icons'
import {
  ArchivedFileIcon,
  CodeFileIcon,
  CompressedFileIcon,
  EmailFileIcon,
  ExcelFileIcon,
  ExcelTemplateFileIcon,
  ExecutableFileIcon,
  FolderIcon,
  GenericFileIcon,
  ImageFileIcon,
  MarkupFileIcon,
  MusicFileIcon,
  OneNoteFileIcon,
  PdfFileIcon,
  PowerPointFileIcon,
  PowerPointTemplateFileIcon,
  ProjectFileIcon,
  RichTextFileIcon,
  SystemFileIcon,
  TextFileIcon,
  VectorImageFileIcon,
  VideoFileIcon,
  WordFileIcon,
  WordTemplateFileIcon
} from './file-icons'
import {
  getFileExtension,
  WORD_FILES,
  WORD_TEMPLATE_FILES,
  EXCEL_FILES,
  EXCEL_TEMPLATE_FILES,
  POWERPOINT_FILES,
  POWERPOINT_TEMPLATE_FILES
} from '../util'

/**
 * Wrappers around Material UI icons with naming that fits our domain.
 */
export const TemplateIcon = FileCopy
export const UnknownIcon = Help
export const SettingsIcon = SettingsApplicationsOutlined
export const LocaleIcon = Language
export const SortAscending = ArrowUpward
export const SortDescending = ArrowDownward

export const PergasIconColored = (props) => {
  return (
    <SvgIcon
      viewBox='0 0 25.179 25.179'
      {...props}
    >
      <path id='Rectangle_13810' d='M0 0H6.867V6.867H0z' fill='#266783' />
      <path id='Rectangle_13811' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(9.156)' />
      <path id='Rectangle_13812' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(18.312)' />
      <path id='Rectangle_13813' d='M0 0H6.867V6.867H0z' fill='#266783' transform='translate(9.156 9.156)' />
      <path id='Rectangle_13814' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(18.312 9.156)' />
      <path id='Rectangle_13815' d='M0 0H6.867V6.867H0z' fill='#266783' transform='translate(0 18.312)' />
      <path id='Rectangle_13816' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(18.312 18.312)' />
    </SvgIcon>
  )
}

export const MicrosoftIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path fill='#f35325' d='M0 0h10v10H0z' />
      <path fill='#81bc06' d='M11 0h10v10H11z' />
      <path fill='#05a6f0' d='M0 11h10v10H0z' />
      <path fill='#ffba08' d='M11 11h10v10H11z' />
    </SvgIcon>
  )
}

export const DocumentLibraryIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 0 24 24' width='20'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z' />
      </svg>
    </SvgIcon>
  )
}

export const ContactPersonIcon = ContactsOutlined

export const ContactIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24' viewBox='0 0 24 24' width='24'>
        <rect fill='none' height='24' width='24' />
        <path d='M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z' />
      </svg>
    </SvgIcon>
  )
}

export const ProjectIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24' viewBox='0 0 24 24' width='24'>
        <g><rect fill='none' height='24' width='24' /></g>
        <g>
          <g>
            <path d='M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M20,19H4V5h16V19z' fillRule='evenodd' />
            <polygon fillRule='evenodd' points='19.41,10.42 17.99,9 14.82,12.17 13.41,10.75 12,12.16 14.82,15' />
            <rect fillRule='evenodd' height='2' width='5' x='5' y='7' />
            <rect fillRule='evenodd' height='2' width='5' x='5' y='11' />
            <rect fillRule='evenodd' height='2' width='5' x='5' y='15' />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}

export const DepartmentIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M17 15h2v2h-2zM17 11h2v2h-2zM17 7h2v2h-2zM13.74 7l1.26.84V7z' />
        <path d='M10 3v1.51l2 1.33V5h9v14h-4v2h6V3z' />
        <path d='M8.17 5.7L15 10.25V21H1V10.48L8.17 5.7zM10 19h3v-7.84L8.17 8.09 3 11.38V19h3v-6h4v6z' />
      </svg>
    </SvgIcon>
  )
}

export const CategoryIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M12 2l-5.5 9h11L12 2zm0 3.84L13.93 9h-3.87L12 5.84zM17.5 13c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 7c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM3 21.5h8v-8H3v8zm2-6h4v4H5v-4z' />
      </svg>
    </SvgIcon>
  )
}

export const TemplateManagerIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg>
        <path d='M3.81818 0H0V3.81818H3.81818V0Z' fill='#206A85' />
        <path d='M8.90909 0H5.09091V3.81818H8.90909V0Z' fill='#206A85' />
        <path d='M14 0H10.1818V3.81818H14V0Z' fill='#206A85' />
        <path d='M8.90909 5.09091H5.09091V8.90909H8.90909V5.09091Z' fill='#206A85' />
        <path d='M8.82235 10.1818H5.00417V14H8.82235V10.1818Z' fill='#206A85' />
      </svg>
    </SvgIcon>
  )
}

/**
 * Icons for different types of items.
 */
export function getItemIcon (item) {
  switch (item.type) {
    case 'category':
      return CategoryIcon
    case 'contact':
      return ContactIcon
    case 'department':
      return DepartmentIcon
    case 'rootfolder':
    case 'folder':
      return FolderIcon
    case 'file':
      return getFileIcon(item.name || '')
    case 'project':
      return ProjectIcon
    default:
      return UnknownIcon
  }
}

/**
 * Return a file icon based on file extension.
 */
function getFileIcon (name) {
  const ext = getFileExtension(name)

  // TODO keep refactoring this once we have support for other
  // protocols in the Office URI Scheme

  if (WORD_FILES.includes(ext)) {
    return WordFileIcon
  } else if (WORD_TEMPLATE_FILES.includes(ext)) {
    return WordTemplateFileIcon
  } else if (EXCEL_FILES.includes(ext)) {
    return ExcelFileIcon
  } else if (EXCEL_TEMPLATE_FILES.includes(ext)) {
    return ExcelTemplateFileIcon
  } else if (POWERPOINT_FILES.includes(ext)) {
    return PowerPointFileIcon
  } else if (POWERPOINT_TEMPLATE_FILES.includes(ext)) {
    return PowerPointTemplateFileIcon
  }

  switch (ext) {
    case 'dll':
    case 'lib':
      return SystemFileIcon
    case 'exe':
      return ExecutableFileIcon
    case 'avi':
    case 'mpeg':
    case 'mkv':
      return VideoFileIcon
    case 'mp3':
    case 'ogg':
      return MusicFileIcon
    case 'h':
    case 'c':
    case 'cpp':
    case 'js':
      return CodeFileIcon
    case 'rtf':
      return RichTextFileIcon
    case 'htm':
    case 'html':
    case 'xml':
      return MarkupFileIcon
    case 'tar':
      return ArchivedFileIcon
    case 'zip':
    case 'gz':
      return CompressedFileIcon
    case 'msg':
    case 'eml':
      return EmailFileIcon
    case 'gif':
    case 'ico':
    case 'png':
    case 'jpg':
    case 'jpeg':
      return ImageFileIcon
    case 'svg':
      return VectorImageFileIcon
    case 'mpp':
      return ProjectFileIcon
    case 'one':
      return OneNoteFileIcon
    case 'pdf':
      return PdfFileIcon
    case 'txt':
      return TextFileIcon
    default:
      return GenericFileIcon
  }
}
