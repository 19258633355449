import React, { useState } from 'react'

import { InputAdornment, SvgIcon } from '@material-ui/core'
import { CancelOutlined } from '@material-ui/icons'
import TextField from '../components/TextField.js'
import { HEADER_BACKGROUND, HEADER_HEIGHT } from '../styles.js'

const ENTER = ['Enter', 'NumpadEnter']

const Header = ({
  title,
  value,
  onSearchChange
}) => {
  const [search, setSearch] = useState(value || '')
  const buttonEnabled = search.length > 0

  const resetSearch = () => {
    if (buttonEnabled) {
      setSearch('')
      onSearchChange('')
    }
  }

  const doSearch = () => onSearchChange(search)
  const onChange = (event) => setSearch(event.target.value)

  const onKeyPress = (event) => {
    if (ENTER.includes(event.key)) {
      doSearch()
    }
  }

  const inputProps = {
    endAdornment: (
      <InputAdornment position='end'>
        <CancelOutlined
          style={closeButtonStyle(buttonEnabled)}
          onClick={resetSearch}
        />
      </InputAdornment>
    )
  }

  return (
    <div style={headerStyle}>
      <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
        <a href='https://pds-cm.pergas.se' target='_new'><PergasIcon /></a>
        <span style={titleStyle}>{title}</span>
      </div>
      {onSearchChange && <div style={{ width: '50%' }}><TextField fullWidth={false} value={search} onChange={onChange} InputProps={inputProps} style={searchFieldStyle} onKeyPress={onKeyPress} /></div>}
    </div>
  )
}

const closeButtonStyle = (enabled) => {
  return {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 7,
    right: 0,
    bottom: 9,
    marginRight: 5,
    color: enabled ? '#000000' : '#888888'
  }
}

const titleStyle = {
  color: '#ffffff',
  marginLeft: 10
}

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: HEADER_BACKGROUND,
  height: 50
}

const searchFieldStyle = {
  background: 'white',
  marginTop: 0,
  marginBottom: 0,
  marginRight: 10,
  float: 'right'
}

const PergasIcon = ({ onClick }) => {
  return (
    <SvgIcon
      style={{ cursor: 'pointer', height: HEADER_HEIGHT, paddingLeft: '15px' }}
      viewBox='0 0 80 80'
      onClick={onClick}
    >
      <g transform='translate(0 0.001)'>
        <rect width='21.818' height='21.818' transform='translate(0 -0.001)' fill='#fefefe' />
        <rect width='21.818' height='21.818' transform='translate(29.091 -0.001)' fill='#fefefe' />
        <rect width='21.818' height='21.818' transform='translate(58.182 -0.001)' fill='#fefefe' />
        <rect width='21.818' height='21.818' transform='translate(29.091 29.09)' fill='#fefefe' />
        <rect width='21.818' height='21.818' transform='translate(58.182 29.09)' fill='#fefefe' />
        <rect width='21.818' height='21.818' transform='translate(0 58.181)' fill='#fefefe' />
        <rect width='21.818' height='21.818' transform='translate(58.182 58.181)' fill='#fefefe' />
      </g>
    </SvgIcon>
  )
}

export default Header
