import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const fillWhite = { fill: '#fff' }
const fillC8 = { fill: '#c8c8c8' }
const fillEA = { fill: '#eaeaea' }
const fillA6 = { fill: '#a6a6a6' }
const fillWhiteOp6 = { fill: '#fff', opacity: 0.6 }
const fillRed = { fill: '#d65532' }
const fillGreen = { fill: '#d65532' }
const fillOrange = { fill: '#f5ba9d' }
const fillBlue1 = { fill: '#0078d7' }
const fillBlue2 = { fill: '#deebf8' }
const fillBlue3 = { fill: '#9ed1f1' }
const fillBlue4 = { fill: '#69afe5' }

export const ExcelFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='xcel_-_16px' transform='translate(12379 16298)'>
        <path id='Path_89' d='M46 173H36v-14h8l2 2z' style={fillWhite} transform='translate(-12412 -16456)' />
        <path id='Path_90' fill='#a6a6a6' d='M44 158h-9v16h12v-13zm0 1.5l1.5 1.5H44zm2 13.5H36v-14h7v3h3z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_196' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12372 -16287)' />
        <path id='Rectangle_197' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12372 -16289)' />
        <path id='Rectangle_198' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12372 -16291)' />
        <path id='Rectangle_199' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12372 -16293)' />
        <path id='Rectangle_200' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12372 -16285)' />
        <path id='Rectangle_201' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12369 -16287)' />
        <path id='Rectangle_202' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12369 -16289)' />
        <path id='Rectangle_203' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12369 -16291)' />
        <path id='Rectangle_204' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12369 -16293)' />
        <path id='Rectangle_205' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12369 -16285)' />
        <path id='Path_91' fill='#008641' d='M33 163l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <g id='Group_134' transform='translate(-12412 -16456)'>
          <path id='Rectangle_206' d='M0 0H1V1H0z' style={fillWhite} transform='translate(35 165)' />
          <path id='Rectangle_207' d='M0 0H1V2H0z' style={fillWhite} transform='translate(36 166)' />
          <path id='Rectangle_208' d='M0 0H1V1H0z' style={fillWhite} transform='translate(37 165)' />
          <path id='Rectangle_209' d='M0 0H1V1H0z' style={fillWhite} transform='translate(35 168)' />
          <path id='Rectangle_210' d='M0 0H1V1H0z' style={fillWhite} transform='translate(37 168)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const ExcelTemplateFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='xcel_template_-_16px' transform='translate(12379 15274)'>
        <path id='Path_230' d='M45 1182H35v14h2v2h10v-14z' style={fillWhite} transform='translate(-12412 -16456)' />
        <path id='Rectangle_394' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12371 -15269)' />
        <path id='Rectangle_395' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12371 -15267)' />
        <path id='Rectangle_396' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12371 -15265)' />
        <path id='Rectangle_397' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12371 -15263)' />
        <path id='Path_231' fill='#a6a6a6' d='M46 1197h-8v-1h7v-11h-1v10h-8v-10h-1v11h2v2h10v-12l-1-1z' transform='translate(-12412 -16456)' />
        <path id='Path_232' fill='#8bbf8a' d='M45 1182H35v3h10v-1l2 2v-2z' transform='translate(-12412 -16456)' />
        <path id='Path_233' fill='#008641' d='M33 1187l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <g id='Group_221' transform='translate(-12412 -16456)'>
          <path id='Rectangle_398' d='M0 0H1V1H0z' style={fillWhite} transform='translate(35 1189)' />
          <path id='Rectangle_399' d='M0 0H1V2H0z' style={fillWhite} transform='translate(36 1190)' />
          <path id='Rectangle_400' d='M0 0H1V1H0z' style={fillWhite} transform='translate(37 1189)' />
          <path id='Rectangle_401' d='M0 0H1V1H0z' style={fillWhite} transform='translate(35 1192)' />
          <path id='Rectangle_402' d='M0 0H1V1H0z' style={fillWhite} transform='translate(37 1192)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const WordFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='word_-_16px' transform='translate(12379 16362)'>
        <path id='Path_80' d='M46 109H36V95h8l2 2z' style={fillWhite} transform='translate(-12412 -16456)' />
        <path id='Path_81' fill='#a6a6a6' d='M44 94h-9v16h12V97zm0 1.5l1.5 1.5H44zm2 13.5H36V95h7v3h3z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_179' d='M0 0H5V1H0z' style={fillC8} transform='translate(-12372 -16351)' />
        <path id='Rectangle_180' d='M0 0H5V1H0z' style={fillC8} transform='translate(-12372 -16353)' />
        <path id='Rectangle_181' d='M0 0H5V1H0z' style={fillC8} transform='translate(-12372 -16355)' />
        <path id='Rectangle_182' d='M0 0H5V1H0z' style={fillC8} transform='translate(-12372 -16357)' />
        <path id='Path_82' fill='#2c5898' d='M33 99l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_183' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12375 -16360)' />
        <g id='Group_130' transform='translate(-12412 -16456)'>
          <path id='Rectangle_184' d='M0 0H1V2H0z' style={fillWhite} transform='translate(34 101)' />
          <path id='Rectangle_185' d='M0 0H1V2H0z' style={fillWhite} transform='translate(35 103)' />
          <path id='Rectangle_186' d='M0 0H1V2H0z' style={fillWhite} transform='translate(36 101)' />
          <path id='Rectangle_187' d='M0 0H1V2H0z' style={fillWhite} transform='translate(37 103)' />
          <path id='Rectangle_188' d='M0 0H1V2H0z' style={fillWhite} transform='translate(38 101)' />
          <path id='Rectangle_189' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(34 103)' />
          <path id='Rectangle_190' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(38 103)' />
          <path id='Rectangle_191' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(36 103)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const WordTemplateFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='word_template_-_16px' transform='translate(12379 15338)'>
        <path id='Path_219' d='M45 1118H35v14h2v2h10v-14z' style={fillWhite} transform='translate(-12412 -16456)' />
        <path id='Path_220' fill='#a6a6a6' d='M46 1133h-8v-1h7v-11h-1v10h-8v-10h-1v11h2v2h10v-12l-1-1z' transform='translate(-12412 -16456)' />
        <path id='Path_221' fill='#acbbe3' d='M45 1118H35v3h10v-1l2 2v-2z' transform='translate(-12412 -16456)' />
        <path id='Path_222' fill='#2c5898' d='M33 1123l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <g id='Group_217' transform='translate(-12412 -16456)'>
          <path id='Rectangle_382' d='M0 0H1V2H0z' style={fillWhite} transform='translate(34 1125)' />
          <path id='Rectangle_383' d='M0 0H1V2H0z' style={fillWhite} transform='translate(35 1127)' />
          <path id='Rectangle_384' d='M0 0H1V2H0z' style={fillWhite} transform='translate(36 1125)' />
          <path id='Rectangle_385' d='M0 0H1V2H0z' style={fillWhite} transform='translate(37 1127)' />
          <path id='Rectangle_386' d='M0 0H1V2H0z' style={fillWhite} transform='translate(38 1125)' />
        </g>
        <path id='Rectangle_387' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -15327)' />
        <path id='Rectangle_388' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -15329)' />
        <path id='Rectangle_389' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -15331)' />
        <path id='Rectangle_390' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -15333)' />
      </g>
    </SvgIcon>
  )
}

export const PowerPointFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='ppt_-_16px' transform='translate(12379 16330)'>
        <path id='Path_83' d='M46 141H36v-14h8l2 2z' style={fillWhite} transform='translate(-12412 -16456)' />
        <path id='Path_84' fill='#f5ba9d' d='M42 129a2.006 2.006 0 0 1 2 2h-2z' transform='translate(-12412 -16456)' />
        <path id='Path_85' fill='#a6a6a6' d='M44 126h-9v16h12v-13zm0 1.5l1.5 1.5H44zm2 13.5H36v-14h7v3h3z' transform='translate(-12412 -16456)' />
        <path id='Path_86' d='M44 132a3 3 0 1 1-3-3v3z' style={fillC8} transform='translate(-12412 -16456)' />
        <path id='Rectangle_192' d='M0 0H5V1H0z' style={fillC8} transform='translate(-12372 -16319)' />
        <path id='Rectangle_193' d='M0 0H5V1H0z' style={fillC8} transform='translate(-12372 -16317)' />
        <path id='Path_87' fill='#f04e23' d='M33 131l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <g id='Group_132' transform='translate(-12412 -16456)'>
          <path id='Path_88' d='M37 133h-2v4h1v-1h1v-1h1v-1h-1zm0 2h-1v-1h1z' style={fillWhite} />
          <path id='Rectangle_194' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(37 135)' />
          <path id='Rectangle_195' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(37 133)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const PowerPointTemplateFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='ppt_template_-_16px' transform='translate(12379 15306)'>
        <path id='Path_223' d='M45 1150H35v14h2v2h10v-14z' style={fillWhite} transform='translate(-12412 -16456)' />
        <path id='Path_224' fill='#a6a6a6' d='M46 1165h-8v-1h7v-11h-1v10h-8v-10h-1v11h2v2h10v-12l-1-1z' transform='translate(-12412 -16456)' />
        <path id='Path_225' d='M45 1150H35v3h10v-1l2 2v-2z' style={fillOrange} transform='translate(-12412 -16456)' />
        <path id='Path_226' d='M43 1157a3 3 0 1 1-3-3v3z' style={fillC8} transform='translate(-12412 -16456)' />
        <path id='Path_227' fill='#f04e23' d='M33 1155l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <path id='Path_228' d='M43 1156h-2v-2a2.006 2.006 0 0 1 2 2z' style={fillOrange} transform='translate(-12412 -16456)' />
        <path id='Rectangle_391' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -15295)' />
        <g id='Group_219' transform='translate(-12412 -16456)'>
          <path id='Path_229' d='M37 1157h-2v4h1v-1h1v-1h1v-1h-1zm0 2h-1v-1h1z' style={fillWhite} />
          <path id='Rectangle_392' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(37 1159)' />
          <path id='Rectangle_393' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(37 1157)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const GenericFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 12 16' style={{ height: 16, width: 16 }}>
      <g>
        <path fill='#fff' d='M45 269H35v-14h8l2 2z' transform='translate(12378 16202) translate(-12412 -16456)' />
        <path fill='#a6a6a6' d='M43 254h-9v16h12v-13zm0 1.5l1.5 1.5H43zm2 13.5H35v-14h7v3h3z' transform='translate(12378 16202) translate(-12412 -16456)' />
      </g>
    </SvgIcon>
  )
}

export const PdfFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='pdf_-_16px' transform='translate(12379 16426)'>
        <path id='Path_75' fill='#fff' d='M45 45H35V31h8l2 2z' transform='translate(-12412 -16456)' />
        <path id='Path_76' fill='#a6a6a6' d='M43 30h-9v16h12V33zm0 1.5l1.5 1.5H43zM45 45H35V31h7v3h3z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_169' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16421)' />
        <path id='Rectangle_170' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16419)' />
        <path id='Rectangle_171' d='M0 0H3V3H0z' style={fillRed} transform='translate(-12368 -16416)' />
        <path id='Rectangle_172' d='M0 0H3V3H0z' style={fillRed} transform='translate(-12379 -16416)' />
        <g id='Group_126' transform='translate(-12412 -16456)'>
          <path id='Path_77' d='M42 40v3h-4v-3h4m1-1h-6v5h6v-5z' style={fillRed} />
        </g>
        <path id='Rectangle_173' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12376 -16424)' />
      </g>
    </SvgIcon>
  )
}

export const TextFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='txt_file_-_16px' transform='translate(12378 16394)'>
        <g id='Group_128'>
          <path id='Path_78' fill='#fff' d='M45 77H35V63h8l2 2z' transform='translate(-12412 -16456)' />
          <path id='Path_79' fill='#a6a6a6' d='M43 62h-9v16h12V65zm0 1.5l1.5 1.5H43zM45 77H35V63h7v3h3z' transform='translate(-12412 -16456)' />
          <path id='Rectangle_174' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16383)' />
          <path id='Rectangle_175' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16385)' />
          <path id='Rectangle_176' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16387)' />
          <path id='Rectangle_177' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16389)' />
          <path id='Rectangle_178' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12376 -16392)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const OneNoteFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='onetoc_-_16px' transform='translate(12379 16266)'>
        <path id='Path_92' d='M45 205h-9v-14h9v1h1v3h-1z' style={fillWhite} transform='translate(-12412 -16456)' />
        <path id='Path_93' d='M45 191v1h1v3h-1v10h-9v-14h9m1-1H35v16h11v-10h1v-5h-1v-1z' style={fillA6} transform='translate(-12412 -16456)' />
        <path id='Rectangle_211' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -16259)' />
        <path id='Rectangle_212' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -16257)' />
        <path id='Rectangle_213' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12372 -16261)' />
        <g id='Group_136' transform='translate(-12412 -16456)'>
          <path id='Rectangle_214' d='M0 0H1V3H0z' style={fillA6} transform='translate(46 197)' />
        </g>
        <g id='Group_137' transform='translate(-12412 -16456)'>
          <path id='Rectangle_215' d='M0 0H1V3H0z' style={fillA6} transform='translate(46 201)' />
        </g>
        <path id='Path_94' fill='#803a7a' d='M33 195l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <g id='Group_138' transform='translate(-12412 -16456)'>
          <path id='Rectangle_216' d='M0 0H1V4H0z' style={fillWhite} transform='translate(35 197)' />
          <path id='Rectangle_217' d='M0 0H1V1H0z' style={fillWhite} transform='translate(35 198)' />
          <path id='Rectangle_218' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(36 198)' />
          <path id='Rectangle_219' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(35 197)' />
          <path id='Rectangle_220' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(37 200)' />
          <path id='Rectangle_221' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(36 199)' />
          <path id='Rectangle_222' d='M0 0H1V1H0z' style={fillWhite} transform='translate(37 199)' />
          <path id='Rectangle_223' d='M0 0H1V4H0z' style={fillWhite} transform='translate(37 197)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const ProjectFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 14 16' style={{ height: 16, width: 16 }}>
      <g id='project_-_16px' transform='translate(12379 16042)'>
        <g id='Group_150' transform='translate(-12412 -16456)'>
          <path id='Path_111' d='M46 429H36v-14h8l2 2z' style={fillWhite} />
          <path id='Path_112' fill='#a6a6a6' d='M44 414h-9v16h12v-13zm0 1.5l1.5 1.5H44zm2 13.5H36v-14h7v3h3z' />
        </g>
        <path id='Path_113' fill='#c8c8c8' d='M44 419h-4v1h4v7h-4v1h5v-9z' transform='translate(-12412 -16456)' />
        <path id='Path_114' fill='#307439' d='M33 419l7-1v10l-7-1z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_268' d='M0 0H3V1H0z' style={fillGreen} transform='translate(-12372 -16035)' />
        <path id='Rectangle_269' d='M0 0H3V1H0z' style={fillGreen} transform='translate(-12371 -16031)' />
        <path id='Rectangle_270' d='M0 0H3V1H0z' style={fillGreen} transform='translate(-12372 -16033)' />
        <g id='Group_151' transform='translate(-12412 -16456)'>
          <path id='Path_115' d='M37 421h-2v4h1v-1h1v-1h1v-1h-1zm0 2h-1v-1h1z' style={fillWhite} />
          <path id='Rectangle_271' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(37 423)' />
          <path id='Rectangle_272' d='M0 0H1V1H0z' style={fillWhiteOp6} transform='translate(37 421)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const FolderIcon = () => {
  return (
    <SvgIcon viewBox='0 0 16 12' style={{ height: 16, width: 16 }}>
      <g>
        <g>
          <path fill='#a6a6a6' d='M0 140v-10h7l2-2h7v12z' transform='translate(12412 16328) translate(-12412 -16456)' />
          <path fill='#666' d='M9 128l-2 2 2 2h7v-4z' transform='translate(12412 16328) translate(-12412 -16456)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const CreateFolderIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1_13)'>
        <path d='M4 18V8H11L13 6H20V18H4Z' fill='#A6A6A6' />
        <path d='M13 6L11 8L13 10H20V6H13Z' fill='#666666' />
        <rect x='5' y='12' width='8' height='2' fill='#D9D9D9' />
        <rect x='8' y='9' width='2' height='8' fill='#D9D9D9' />
      </g>
      <defs>
        <clipPath id='clip0_1_13'>
          <rect width='16' height='12' fill='white' transform='translate(4 6)' />
        </clipPath>
      </defs>
    </svg>

  )
}

export const ImageFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 16 12' style={{ height: 16, width: 16 }}>
      <g>
        <path fill='#fff' d='M0 0H14V10H0z' transform='translate(12380 14952) translate(-12379 -14951)' />
        <path fill='#7dbab2' d='M47 1514.7v-1.4l-4-4-2.5 2.5-4.5-4.5-3 3v1.4l3-3 6.3 6.3h1.4l-2.5-2.5 1.8-1.8z' transform='translate(12380 14952) translate(-12412 -16456)' />
        <path fill='#f5ba9d' d='M0 0H1V1H0z' transform='translate(12380 14952) translate(-12368 -14949)' />
        <path fill='#a6a6a6' d='M32 1504v12h16v-12zm15 11H33v-10h14z' transform='translate(12380 14952) translate(-12412 -16456)' />
      </g>
    </SvgIcon>
  )
}

export const VectorImageFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 12 16' style={{ height: 16, width: 16 }}>
      <g id='vector_-_16px' transform='translate(12378 15914)'>
        <g id='Group_174' transform='translate(-12412 -16456)'>
          <path id='Path_132' fill='#fff' d='M45 557H35v-14h8l2 2z' />
          <path id='Path_133' fill='#a6a6a6' d='M43 542h-9v16h12v-13zm0 1.5l1.5 1.5H43zm2 13.5H35v-14h7v3h3z' />
        </g>
        <circle id='Ellipse_58' cx='1' cy='1' r='1' style={fillBlue1} transform='translate(-12376 -15908)' />
        <g id='Group_175' transform='translate(-12412 -16456)'>
          <path id='Path_134' d='M44 554l-1.5.5-.5 1.5-2-4z' style={fillBlue1} />
        </g>
        <path id='Path_135' d='M38 554v1h-1v-1h1m1-1h-3v3h3v-3z' style={fillBlue1} transform='translate(-12412 -16456)' />
        <g id='Group_176' transform='translate(-12412 -16456)'>
          <path id='Rectangle_293' d='M0 0H1V1H0z' style={fillC8} transform='translate(37 551)' />
        </g>
        <path id='Path_136' d='M43 549v1h-1v-1h1m1-1h-3v3h3v-3z' style={fillBlue1} transform='translate(-12412 -16456)' />
        <g id='Group_177' transform='translate(-12412 -16456)'>
          <path id='Rectangle_294' d='M0 0H1V1H0z' style={fillC8} transform='translate(39 549)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const EmailFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 16 12' style={{ height: 16, width: 16 }}>
      <g id='email_-_16px' transform='translate(12380 16072)'>
        <path id='Rectangle_263' fill='#fff' d='M0 0H14V10H0z' transform='translate(-12379 -16071)' />
        <path id='Path_110' fill='#a6a6a6' d='M32 384v12h16v-12zm15 11H33v-10h14z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_264' d='M0 0H2V1H0z' style={fillC8} transform='translate(-12378 -16070)' />
        <path id='Rectangle_265' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16066)' />
        <path id='Rectangle_266' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -16064)' />
        <g id='Group_148' transform='translate(-12412 -16456)'>
          <path id='Rectangle_267' fill='#69afe5' d='M0 0H2V2H0z' transform='translate(44 386)' />
        </g>
      </g>
    </SvgIcon>
  )
}

export const CompressedFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 16 12' style={{ height: 16, width: 16 }}>
      <g id='zip_folder_-_16px' transform='translate(12412 16392)'>
        <path id='Path_279' fill='#a6a6a6' d='M0 76V66h7l2-2h7v12z' transform='translate(-12412 -16456)' />
        <path id='Path_280' fill='#666' d='M9 64l-2 2 2 2h7v-4z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_473' d='M0 0H1V1H0z' style={fillEA} transform='translate(-12410 -16390)' />
        <path id='Rectangle_474' d='M0 0H1V1H0z' style={fillEA} transform='translate(-12410 -16383)' />
        <path id='Rectangle_475' d='M0 0H1V1H0z' style={fillEA} transform='translate(-12410 -16381)' />
        <path id='Path_281' d='M3 67v1H2v-1H1v5h3v-5zm0 4H2v-1h1z' style={fillEA} transform='translate(-12412 -16456)' />
      </g>
    </SvgIcon>
  )
}

export const ArchivedFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 16 12' style={{ height: 16, width: 16 }}>
      <g id='archived_folder_-_16px' transform='translate(12412 16456)'>
        <path id='Path_273' fill='#a6a6a6' d='M0 12V2h7l2-2h7v12z' transform='translate(-12412 -16456)' />
        <path id='Path_274' fill='#666' d='M9 0L7 2l2 2h7V0z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_471' d='M0 0H2V1H0z' style={fillEA} transform='translate(-12410 -16454)' />
        <path id='Rectangle_472' d='M0 0H2V2H0z' style={fillEA} transform='translate(-12410 -16446)' />
        <path id='Path_275' d='M1 4v5h4V4zm3 1v3H2V5z' style={fillEA} transform='translate(-12412 -16456)' />
      </g>
    </SvgIcon>
  )
}

export const MarkupFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 12 16' style={{ height: 16, width: 16 }}>
      <g id='html_-_16px' transform='translate(12378 15786)'>
        <path id='Path_143' fill='#fff' d='M45 685H35v-14h8l2 2z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_315' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -15775)' />
        <path id='Rectangle_316' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -15773)' />
        <path id='Path_144' fill='#a6a6a6' d='M43 670h-9v16h12v-13zm0 1.5l1.5 1.5H43zm2 13.5H35v-14h7v3h3z' transform='translate(-12412 -16456)' />
        <path id='Path_145' d='M37 679h1l-1-1.5 1-1.5h-1l-1 1.5z' style={fillC8} transform='translate(-12412 -16456)' />
        <path id='Path_146' d='M43 679h-1l1-1.5-1-1.5h1l1 1.5z' style={fillC8} transform='translate(-12412 -16456)' />
        <g id='Group_186' transform='translate(-12412 -16456)'>
          <circle id='Ellipse_59' cx='1.5' cy='1.5' r='1.5' fill='#6caedf' transform='translate(38.5 676)' />
          <g id='Group_185'>
            <path id='Path_148' d='M39.2 677.4l.1.1c.1-.1 0-.1-.1-.1z' style={fillBlue2} />
            <path id='Path_149' d='M39.2 677.3s-.1-.2-.1-.1c-.1.1 0 .1.1.1z' style={fillBlue2} />
            <path id='Path_150' d='M41.2 676.6a.1.1 0 0 1-.1.1H41c0-.1-.1 0-.1 0a.1.1 0 0 1-.1.1v.5l.1.1a.1.1 0 0 0 .1.1l.1.1h.1a.1.1 0 0 0 .1-.1.1.1 0 0 1 .1-.1v.1a.959.959 0 0 0-.2-.9z' style={fillBlue2} />
            <path id='Path_151' d='M40.4 676.1h-.1c-.1 0 .1.1.1.1h.1c.1 0 0 0-.1-.1.1.1.1 0 .2.1h.1v.3a.1.1 0 0 1 .1.1h-.1v.1l.1.1h.1V676.5h.1v-.1h-.1l.1.1v-.2c-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1h-.2z' style={fillBlue2} />
            <path id='Path_152' d='M40.6 676.3l-.1-.1c-.1.1 0 .2.1.1z' style={fillBlue2} />
            <path id='Path_153' d='M39.8 678.8l.1-.1c0-.1.1-.1.2-.1s.1 0 .1-.1a.1.1 0 0 1 .1-.1l.1-.1v-.1h-.3l-.1-.1h-.1v-.1a.1.1 0 0 1-.1-.1h-.2l-.1-.1h-.2a.1.1 0 0 1-.1.1s0-.1-.1 0l-.1-.1a.1.1 0 0 1-.1-.1v-.2s.1-.1 0-.1h-.1c-.1 0-.1 0-.1-.1v-.1l.1-.1h.3v.2l.1-.1a.1.1 0 0 1 .1-.1.1.1 0 0 0 .1-.1h.1s.1-.1.1 0a.1.1 0 0 0 .1-.1h.1v.1h.1c-.1 0 0-.1 0-.1h.1c.1-.1 0 .1 0 .1h.1v-.2c0-.1-.1 0-.1-.1v-.1h-.2a.1.1 0 0 0-.1.1c.1-.1-.1-.1 0-.2h.3a.1.1 0 0 0 .1.1c.1 0 .1 0 .1-.1s-.1-.1-.2-.1h.1a.6.6 0 0 0-.4.1 2488157712562.048 2488157712562.048 0 0 1-.2.2 1.344 1.344 0 0 0-.6.7v.3h.1v.2h.1v.1c0 .1 0 .1.1.1v.2l-.1.1.1.1v.1l.1.1.1.1a.3.3 0 0 1 0 .4v.1c.1 0 .2.1.3.1 0-.5 0-.6.1-.6zm-.4-2.3c0-.1.1-.1.1 0v.2c-.1-.1-.1-.1-.1-.2 0 .1-.1.1 0 0z' style={fillBlue2} />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export const RichTextFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 12 16' style={{ height: 16, width: 16 }}>
      <g id='rich_txt_-_16px' transform='translate(12378 15882)'>
        <g id='Group_179' transform='translate(-12412 -16456)'>
          <path id='Path_137' fill='#fff' d='M45 589H35v-14h8l2 2z' />
          <path id='Path_138' fill='#a6a6a6' d='M43 574h-9v16h12v-13zm0 1.5l1.5 1.5H43zm2 13.5H35v-14h7v3h3z' />
        </g>
        <path id='Rectangle_295' d='M0 0H4V1H0z' style={fillBlue3} transform='translate(-12372 -15877)' />
        <path id='Rectangle_296' d='M0 0H1V1H0z' style={fillBlue3} transform='translate(-12372 -15879)' />
        <path id='Rectangle_297' fill='#d5edff' d='M0 0H1V1H0z' transform='translate(-12375 -15879)' />
        <path id='Rectangle_298' d='M0 0H8V1H0z' style={fillBlue4} transform='translate(-12376 -15875)' />
        <path id='Rectangle_299' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12376 -15873)' />
        <path id='Rectangle_300' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12376 -15871)' />
        <path id='Rectangle_301' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -15869)' />
        <path id='Rectangle_302' d='M0 0H1V3H0z' style={fillBlue3} transform='translate(-12374 -15879)' />
        <path id='Rectangle_303' d='M0 0H1V1H0z' style={fillBlue3} transform='translate(-12376 -15877)' />
        <path id='Rectangle_304' d='M0 0H1V1H0z' style={fillBlue3} transform='translate(-12375 -15878)' />
        <path id='Rectangle_305' d='M0 0H1V1H0z' style={fillBlue3} transform='translate(-12374 -15880)' />
        <path id='Rectangle_306' d='M0 0H4V3H0z' style={fillBlue4} transform='translate(-12372 -15873)' />
      </g>
    </SvgIcon>
  )
}

export const CodeFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 12 16' style={{ height: 16, width: 16 }}>
      <g id='code_-_16px' transform='translate(12378 15754)'>
        <path id='Path_154' fill='#fff' d='M45 717H35v-14h8l2 2z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_317' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -15743)' />
        <path id='Rectangle_318' d='M0 0H8V1H0z' style={fillC8} transform='translate(-12376 -15741)' />
        <path id='Path_155' fill='#a6a6a6' d='M43 702h-9v16h12v-13zm0 1.5l1.5 1.5H43zm2 13.5H35v-14h7v3h3z' transform='translate(-12412 -16456)' />
        <path id='Path_156' d='M37 711h1l-1-1.5 1-1.5h-1l-1 1.5z' style={fillC8} transform='translate(-12412 -16456)' />
        <path id='Path_157' d='M39 712l3-5h-1l-3 5z' style={fillC8} transform='translate(-12412 -16456)' />
        <path id='Path_158' d='M43 711h-1l1-1.5-1-1.5h1l1 1.5z' style={fillC8} transform='translate(-12412 -16456)' />
      </g>
    </SvgIcon>
  )
}

export const MusicFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 12 16' style={{ height: 16, width: 16 }}>
      <g>
        <path fill='#fff' d='M45 1453H35v-14h8l2 2z' transform='translate(12378 15018) translate(-12412 -16456)' />
        <path fill='#a6a6a6' d='M43 1438h-9v16h12v-13zm0 1.5l1.5 1.5H43zm2 13.5H35v-14h7v3h3z' transform='translate(12378 15018) translate(-12412 -16456)' />
        <path fill='#69afe5' d='M42 1444v-1h-1v-1h-1v6.2a2.959 2.959 0 0 0-1-.2c-1.1 0-2 .7-2 1.5s.9 1.5 2 1.5 2-.7 2-1.5v-4.5h1v1h1v-2z' transform='translate(12378 15018) translate(-12412 -16456)' />
      </g>
    </SvgIcon>
  )
}

export const VideoFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 16 12' style={{ height: 16, width: 16 }}>
      <g id='video_-_16px' transform='translate(12380 14984)'>
        <path id='Rectangle_458' fill='#fff' d='M0 0H14V10H0z' transform='translate(-12379 -14983)' />
        <path id='Path_269' fill='#a6a6a6' d='M32 1472v12h16v-12zm15 11H33v-10h14z' transform='translate(-12412 -16456)' />
        <path id='Path_270' fill='#69afe5' d='M38 1475v6l5-3z' transform='translate(-12412 -16456)' />
        <path id='Rectangle_459' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -14982)' />
        <path id='Rectangle_460' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -14978)' />
        <path id='Rectangle_461' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -14980)' />
        <path id='Rectangle_462' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -14976)' />
        <path id='Rectangle_463' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -14974)' />
        <path id='Rectangle_464' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12367 -14982)' />
        <path id='Rectangle_465' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12367 -14978)' />
        <path id='Rectangle_466' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12367 -14980)' />
        <path id='Rectangle_467' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12367 -14976)' />
        <path id='Rectangle_468' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12367 -14974)' />
      </g>
    </SvgIcon>
  )
}

export const ExecutableFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 16 12' style={{ height: 16, width: 16 }}>
      <g id='exe_-_16px' transform='translate(12380 15848)'>
        <path id='Rectangle_307' fill='#fff' d='M0 0H16V12H0z' transform='translate(-12380 -15848)' />
        <path id='Rectangle_308' d='M0 0H4V1H0z' style={fillC8} transform='translate(-12370 -15845)' />
        <path id='Rectangle_309' d='M0 0H4V1H0z' style={fillC8} transform='translate(-12370 -15843)' />
        <path id='Rectangle_310' d='M0 0H3V1H0z' style={fillC8} transform='translate(-12370 -15841)' />
        <path id='Path_139' fill='#eaeaea' d='M36 619v-9h11v-1H33v10z' transform='translate(-12412 -16456)' />
        <path id='Path_140' fill='#a6a6a6' d='M32 608v12h16v-12zm15 2v9H33v-9h11v-1h1v1h1v-1h1z' transform='translate(-12412 -16456)' />
        <g id='Group_181' transform='translate(-12412 -16456)'>
          <path id='Rectangle_311' fill='#69afe5' d='M0 0H4V6H0z' transform='translate(37 611)' />
        </g>
        <path id='Rectangle_312' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -15845)' />
        <path id='Rectangle_313' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -15843)' />
        <path id='Rectangle_314' d='M0 0H1V1H0z' style={fillC8} transform='translate(-12378 -15841)' />
      </g>
    </SvgIcon>
  )
}

export const SystemFileIcon = () => {
  return (
    <SvgIcon viewBox='0 0 12 16' style={{ height: 16, width: 16 }}>
      <g id='sys_-_16px' transform='translate(12378 15978) translate(-12412 -16456)'>
        <g id='Group_155'>
          <path id='Path_122' fill='#fff' d='M45 493H35v-14h8l2 2z' />
          <path id='Path_123' fill='#a6a6a6' d='M43 478h-9v16h12v-13zm0 1.5l1.5 1.5H43zm2 13.5H35v-14h7v3h3z' />
        </g>
        <g id='Group_168'>
          <g id='Group_156'>
            <path id='Rectangle_277' d='M0 0H2V1H0z' style={fillC8} transform='translate(38 483)' />
          </g>
          <g id='Group_157'>
            <path id='Rectangle_278' d='M0 0H2V1H0z' style={fillC8} transform='translate(38 488)' />
          </g>
          <g id='Group_158'>
            <path id='Rectangle_279' d='M0 0H1V2H0z' style={fillC8} transform='translate(36 485)' />
          </g>
          <g id='Group_159'>
            <path id='Rectangle_280' d='M0 0H1V2H0z' style={fillC8} transform='translate(41 485)' />
          </g>
          <g id='Group_160'>
            <path id='Rectangle_281' d='M0 0H1V1H0z' style={fillC8} transform='translate(37 484)' />
          </g>
          <g id='Group_161'>
            <path id='Rectangle_282' d='M0 0H1V1H0z' style={fillC8} transform='translate(38 485)' />
          </g>
          <g id='Group_162'>
            <path id='Rectangle_283' d='M0 0H1V1H0z' style={fillC8} transform='translate(39 485)' />
          </g>
          <g id='Group_163'>
            <path id='Rectangle_284' d='M0 0H1V1H0z' style={fillC8} transform='translate(39 486)' />
          </g>
          <g id='Group_164'>
            <path id='Rectangle_285' d='M0 0H1V1H0z' style={fillC8} transform='translate(38 486)' />
          </g>
          <g id='Group_165'>
            <path id='Rectangle_286' d='M0 0H1V1H0z' style={fillC8} transform='translate(40 484)' />
          </g>
          <g id='Group_166'>
            <path id='Rectangle_287' d='M0 0H1V1H0z' style={fillC8} transform='translate(37 487)' />
          </g>
          <g id='Group_167'>
            <path id='Rectangle_288' d='M0 0H1V1H0z' style={fillC8} transform='translate(40 487)' />
          </g>
        </g>
        <g id='Group_169'>
          <path id='Rectangle_289' d='M0 0H1V1H0z' style={fillC8} transform='translate(42 488)' />
          <path id='Rectangle_290' d='M0 0H1V1H0z' style={fillC8} transform='translate(41 489)' />
          <path id='Rectangle_291' d='M0 0H1V1H0z' style={fillC8} transform='translate(42 490)' />
          <path id='Rectangle_292' d='M0 0H1V1H0z' style={fillC8} transform='translate(43 489)' />
        </g>
      </g>
    </SvgIcon>
  )
}
