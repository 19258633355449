import React from 'react'
import { connect } from 'react-redux'
import TextField from '../../components/TextField.js'
import redux from '../../redux/index.js'

const Text = ({
  contentType,
  properties,
  onSetProperty
}) => {
  const key = contentType.field_internal_name
  const onChange = (value) => onSetProperty(key, value)
  const value = properties[key] || ''

  return (
    <TextField
      required={contentType.ui_required}
      label={contentType.field_title}
      value={value}
      style={{ background: 'white' }}
      onChange={(event) => onChange(event.target.value)}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    properties: state.save.documentProperties
  }
}

const mapDispatchToProps = (dispatch) => {
  const { save } = redux.actions
  return {
    onSetProperty: (key, value) => {
      dispatch(save.setProperty(key, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Text)
