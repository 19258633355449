import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Header from './Header.js'
import Footer from './Footer.js'
import FilterToolbar from './FilterToolbar.js'
import FileView from './FileView.js'
import FileSearchField from './FileSearchField.js'
import TreeView from './TreeView.js'
import { renderCustomIcons } from './common.js'

import redux from '../redux/index.js'
import { isSelected } from '../util.js'

const IntranetPage = ({
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,

  resetSelectedItem,

  pageItems,
  expand,
  totalCount,
  limit,
  offset,
  orderBy,
  order,
  search,
  showFavorites,
  showRecent,

  onFilterFavoriteToggle,
  onFilterRecentToggle,

  onSetSelectedItem,
  onToggleDepartmentFavorite,
  onToggleCategoryFavorite,
  onClearFileView,
  onPersonWithRoleClick,
  selected,
  locale
}) => {
  useEffect(getPageItems, [limit, offset, orderBy, order, search, showFavorites, showRecent])

  const checkSelected = isSelected(selected)

  const onFavoriteClick = (item) => {
    const clearFileView = (showFavorites &&
                           item &&
                           item.is_favorite &&
                           checkSelected(item))
    if (item.type === 'department') {
      onToggleDepartmentFavorite(item)
    } else if (item.type === 'category') {
      onToggleCategoryFavorite(item)
    }
    if (clearFileView) {
      onClearFileView()
    }
  }

  const [isDragging, setIsDragging] = useState(false)
  const [origDbViewHeight, setOrigDbViewHeight] = useState(0)
  const [origFileViewHeight, setOrigFileViewHeight] = useState(0)
  const [dbViewHeight, setDbViewHeight] = useState('60%')
  const [fileViewHeight, setFileViewHeight] = useState('40%')
  const [dragY, setDragY] = useState(0)

  const onMouseDown = (e) => {
    e.preventDefault()
    setIsDragging(true)
    setDragY(e.pageY)
    const dbView = document.getElementById('db-view-wrapper')
    setOrigDbViewHeight(dbView.clientHeight)
    const fileView = document.getElementById('file-view-wrapper')
    setOrigFileViewHeight(fileView.clientHeight)
  }

  const onMouseUp = (e) => setIsDragging(false)

  const onMouseMove = (e) => {
    if (isDragging) {
      const diffY = e.pageY - dragY
      setDbViewHeight(origDbViewHeight + diffY)
      setFileViewHeight(origFileViewHeight - diffY)
    }
  }

  const pageCount = Math.ceil(totalCount / limit)
  const currentPage = Math.ceil(offset / limit) + 1
  const onPageChange = (nextPage) => setOffset((nextPage - 1) * limit)
  const onChangeLimit = (l) => setLimit(l)
  const onSortChange = () => {
    onClearFileView()
    setOrder(orderBy, order === 'asc' ? 'desc' : 'asc')
  }
  const onSearchChange = (str) => {
    if (search !== str) resetSelectedItem()
    setOffset(0)
    setSearch(str)
  }

  const paginatorText = pageCount > 0 ? ` (${currentPage}/${pageCount})` : ''
  const title = `${locale.intranet_page_title}${paginatorText}`
  const customIcons = renderCustomIcons({
    locale,
    onPersonWithRoleClick,
    onFavoriteClick,
    sharePointSaveEnabled: true
  })

  return (
    <>
      <Header
        title={title}
        value={search}
        onSearchChange={onSearchChange}
      />
      <FilterToolbar
        onFilterFavoriteToggle={onFilterFavoriteToggle}
        showFavorites={showFavorites}
        onFilterRecentToggle={onFilterRecentToggle}
        showRecent={showRecent}
        onSortChange={onSortChange}
        order={order}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
        limit={limit}
        onChangeLimit={onChangeLimit}
      />
      <div
        style={{
          position: 'absolute',
          top: 90,
          bottom: 90,
          width: '100%'
        }}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <div id='db-view-wrapper' style={treeViewStyle(dbViewHeight)}>
          <TreeView
            items={pageItems}
            expand={expand}
            isSelected={checkSelected}
            onItemSelected={(item) => onSetSelectedItem(item, expand)}
            renderCustomIcons={customIcons}
          />
        </div>
        <div style={resizeSliderStyle} onMouseDown={onMouseDown} />
        <div id='file-view-wrapper' style={fileViewStyle(fileViewHeight)}><FileView /></div>
      </div>
      <div style={fileSearchFieldStyle}><FileSearchField /></div>
      <div style={footerStyle}><Footer /></div>
    </>
  )
}

const border = '1px solid #a3a3a3'

const treeViewStyle = (height) => {
  return {
    background: 'white',
    overflow: 'auto',
    borderTop: border,
    borderLeft: border,
    borderRight: border,
    height
  }
}

const fileSearchFieldStyle = {
  background: 'white',
  position: 'absolute',
  bottom: 50,
  width: '100%'
}

const resizeSliderStyle = {
  width: '100%',
  height: 3,
  borderTop: border,
  borderLeft: border,
  borderRight: border,
  background: 'rgb(202, 221, 204)',
  cursor: 'ns-resize'
}

const fileViewStyle = (height) => {
  return {
    background: 'white',
    overflow: 'auto',
    height,
    border
  }
}

const footerStyle = {
  position: 'absolute',
  bottom: 0,
  width: '100%'
}

const mapStateToProps = (state) => {
  const {
    pageItems,
    expand,
    totalCount,
    limit,
    offset,
    orderBy,
    order,
    search,
    showFavorites,
    showRecent
  } = state.department

  return {
    pageItems,
    expand,
    totalCount,
    limit,
    offset,
    orderBy,
    order,
    search,
    showFavorites,
    showRecent,

    selected: state.selected.current,
    locale: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const {
    department,
    category,
    dialog,
    files,
    selected
  } = redux.actions
  return {
    getPageItems: () => {
      dispatch(department.getPageItems())
    },
    setOrder: (orderBy, order) => dispatch(department.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(department.setLimit(limit)),
    setOffset: (offset) => dispatch(department.setOffset(offset)),
    resetSelectedItem: () => dispatch(selected.resetSelectedItem()),
    setSearch: (search) => {
      dispatch(department.setSearch(search))
    },

    onSetSelectedItem: (item, expand) => {
      if (item.type === 'department') {
        if (!expand[item.id]) {
          dispatch(department.getCategories(item.id))
        }
        dispatch(department.toggleExpandNode(item))
      }
      dispatch(selected.setSelectedItem(item))
      dispatch(files.getRootFolder(item))
    },
    onPersonWithRoleClick: (_, item) => {
      dispatch(dialog.showPeopleDialog(null, item.person_role))
    },
    onClearFileView: () => {
      dispatch(selected.resetSelectedItem())
    },
    onToggleDepartmentFavorite: (d) => {
      dispatch(department.toggleFavorite(d))
    },
    onToggleCategoryFavorite: (c) => {
      dispatch(category.toggleFavorite(c))
    },
    onFilterFavoriteToggle: () => {
      dispatch(selected.resetSelectedItem())
      dispatch(department.toggleShowFavorites())
    },
    onFilterRecentToggle: () => {
      dispatch(selected.resetSelectedItem())
      dispatch(department.toggleShowRecent())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntranetPage)
