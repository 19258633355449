import { busy, notBusy } from '../busy/actions'
import { handleGetErrors, handleUpdateErrors } from '../error/actions'
import { getUserData } from '../common'

export const DEPARTMENT_UPDATE_ACTION = 'DEPARTMENT_UPDATE_ACTION'
export const DEPARTMENT_DELETE_ACTION = 'DEPARTMENT_DELETE_ACTION'
export const DEPARTMENT_EXPAND_NODE_ACTION = 'DEPARTMENT_EXPAND_NODE_ACTION'

export const DEPARTMENT_GET_PAGE_ITEMS_ACTION = 'DEPARTMENT_GET_PAGE_ITEMS_ACTION'
export const DEPARTMENT_GET_CATEGORIES_ACTION = 'DEPARTMENT_GET_CATEGORIES_ACTION'
export const DEPARTMENT_SET_ORDER_ACTION = 'DEPARTMENT_SET_ORDER_ACTION'
export const DEPARTMENT_SET_LIMIT_ACTION = 'DEPARTMENT_SET_LIMIT_ACTION'
export const DEPARTMENT_SET_OFFSET_ACTION = 'DEPARTMENT_SET_OFFSET_ACTION'
export const DEPARTMENT_SET_SEARCH_ACTION = 'DEPARTMENT_SET_SEARCH_ACTION'
export const DEPARTMENT_TOGGLE_SHOW_FAVORITES = 'DEPARTMENT_TOGGLE_SHOW_FAVORITES'
export const DEPARTMENT_TOGGLE_SHOW_RECENT = 'DEPARTMENT_TOGGLE_SHOW_RECENT'

export function departmentUpdateAction (department) {
  return {
    type: DEPARTMENT_UPDATE_ACTION,
    department
  }
}

export function departmentDeleteAction (department) {
  return {
    type: DEPARTMENT_DELETE_ACTION,
    department
  }
}

export function initDepartmentActions (api, store) {
  function getPageItems () {
    const state = store.getState()
    const {
      search,
      limit,
      offset,
      orderBy,
      order,
      showFavorites,
      showRecent
    } = state.department

    const sort = orderBy ? `${orderBy}.${order}` : null
    const params = {
      query: [],
      limit,
      offset,
      sort,
      recent: showRecent
    }

    if (typeof search === 'string' && search.length > 0) {
      params.query.push({ key: 'name', value: search })
    }

    if (showFavorites) {
      params.query.push({ key: 'is_favorite', value: showFavorites, equal: true })
    }

    return function (dispatch) {
      dispatch(busy())
      api.getDepartments(params, getUserData(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: DEPARTMENT_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value,
          totalCount: result.data.total_count
        })
      }).catch(handleGetErrors('department', dispatch))
    }
  }

  function getCategories (departmentId) {
    return function (dispatch) {
      const query = [{ key: 'collection_id', value: departmentId, equal: true }]
      dispatch(busy())
      api.getCategories({ query }, getUserData(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: DEPARTMENT_GET_CATEGORIES_ACTION,
          departmentId,
          categories: result.data.value
        })
      }).catch(handleGetErrors('category', dispatch))
    }
  }

  function toggleExpandNode (department) {
    return {
      type: DEPARTMENT_EXPAND_NODE_ACTION,
      department
    }
  }

  function toggleFavorite (department) {
    return function (dispatch) {
      const favorite = !department.is_favorite
      dispatch(busy())
      api.setDepartmentFavorite(department.id, favorite, getUserData(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('department', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: DEPARTMENT_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: DEPARTMENT_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: DEPARTMENT_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: DEPARTMENT_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Toggle show favorites
   */
  function toggleShowFavorites () {
    return {
      type: DEPARTMENT_TOGGLE_SHOW_FAVORITES
    }
  }

  /**
   * Toggle show recent
   */
  function toggleShowRecent () {
    return {
      type: DEPARTMENT_TOGGLE_SHOW_RECENT
    }
  }

  return {
    getPageItems,
    getCategories,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    toggleExpandNode,
    toggleFavorite,
    toggleShowFavorites,
    toggleShowRecent
  }
}
