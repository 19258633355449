import { SharePointAPIClient } from '@pergas-pds/pds-sp-client'
import { eachSeries } from 'async'
import { ERROR_ACTION } from '../error/actions.js'
import {
  beginProgressDialog,
  updateProgressDialog,
  endProgressDialog
} from '../progress/actions.js'
import {
  closeOutlookWindow,
  emailFileNameOutlookComposeMode,
  getFileExtensionFromHost,
  isOffice,
  isOutlook,
  isOutlookComposeMode,
  readCustomXml,
  readFileAttachments,
  readOfficeFile,
  readCurrentMailFile,
  readMailFile,
  readMailMetadata,
  saveDraftEmail,
  sendMail,
  setDocumentTitle,
  setPDSCategory,
  setDocumentProperty,
  writeCustomXml,
  resetCustomXml,
  getEmailMetadataReadMode,
  setEmailSubjectComposeMode,
  getEmailSubjectComposeMode,
  getEmailAddressComposeMode
} from '../../lib/office.js'
import {
  stringToUint,
  cleanFileName,
  buildFileItemUrl,
  openDocument,
  getFileExtension,
  getItemUrl,
  wait
} from '../../util.js'
import {
  xmlToObject,
  XML_DEFAULT_DATA
} from '../../lib/xml.js'
import { getUserData } from '../common.js'
import config from '../../config.js'

export const SET_SAVE_DIALOG_FILENAME_ACTION = 'SET_SAVE_DIALOG_FILENAME_ACTION'
export const READ_DOCUMENT_PROPERTIES_ACTION = 'READ_DOCUMENT_PROPERTIES_ACTION'
export const SET_PROPERTY_ACTION = 'SET_PROPERTY_ACTION'

const ERROR_TYPE_FILE_SAVE = 'ERROR_TYPE_FILE_SAVE'
const ERROR_TYPE_FILE_READ_ATTACHMENTS = 'ERROR_TYPE_FILE_READ_ATTACHMENTS'
const ERROR_TYPE_FILE_UPLOAD = 'ERROR_TYPE_FILE_UPLOAD'
const ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS = 'ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS'
const ERROR_TYPE_EMAIL_READ = 'ERROR_TYPE_EMAIL_READ'
const ERROR_TYPE_EMAIL_SAVE_DRAFT = 'ERROR_TYPE_EMAIL_SAVE_DRAFT'
const ERROR_TYPE_EMAIL_SEND = 'ERROR_TYPE_EMAIL_SEND'

export function setSaveDialogFileName (fileName) {
  return {
    type: SET_SAVE_DIALOG_FILENAME_ACTION,
    fileName: cleanFileName(fileName)
  }
}

/**
 * Sets a property, but only for saving the state. It doesn't save
 * this property to the document.
 */
export function setProperty (key, value) {
  return {
    type: SET_PROPERTY_ACTION,
    key,
    value
  }
}

/**
 * Async action for reading document properties.
 */
export function readDocumentProperties () {
  return async function (dispatch) {
    let xml

    try {
      xml = await readCustomXml()
      console.info('read custom xml', xml)
    } catch (e) {
      console.warn('failed to read custom xml', e)
      dispatch({
        type: READ_DOCUMENT_PROPERTIES_ACTION,
        documentProperties: {}
      })
      return
    }

    if (!xml) {
      console.info('no custom xml found, using default nil values')
      xml = XML_DEFAULT_DATA
    }
    dispatch({
      type: READ_DOCUMENT_PROPERTIES_ACTION,
      documentProperties: xmlToObject(xml)
    })
  }
}

export function initFileUploadActions (api, store) {
  /**
   * If host extension is the same as the file extension, do nothing.
   * Otherwise append host extension to current file name.
   */
  function computeFileName (fileName) {
    const hostExtension = getFileExtensionFromHost()
    if (hostExtension) {
      const fileExtension = getFileExtension(fileName)
      if (fileExtension === hostExtension) {
        return fileName
      } else {
        return `${fileName}.${hostExtension}`
      }
    } else {
      return fileName
    }
  }

  /**
   * Async action for uploading a file to SharePoint.
   * siteItem: item picked in top tree, e.g. contact, project, intranet, department
   * fileItem: file node picked in the bottom tree
   * fileName: file name for this file
   * xmlProperties: properties to populate custom xml with
   * onSaveDone: call this function when save is done
   */
  function uploadFile (siteItem, fileItem, fileName, xmlProperties, onSaveDone) {
    const state = store.getState()
    const userData = state.login.userData
    fileName = computeFileName(fileName)

    return async function (dispatch) {
      try {
        // TODO figure out how to do this using jszip later, currently modifying
        // existing document!
        await setDocumentTitle(fileName.split('.')[0])
      } catch (e) {
        console.warn('failed to set document title', e)
      }

      const client = SharePointAPIClient({
        scRoot: userData.sc_root,
        token: userData.accessToken
      })

      const url = getItemUrl(siteItem)
      const path = fileItem.path

      let fileData = null

      try {
        dispatch(
          beginProgressDialog(
            'PROGRESS_TITLE_UPLOAD_FILE',
            'PROGRESS_STATUS_READ_FILE'
          )
        )

        if (isOffice()) {
          fileData = await readOfficeFile()
          // Read office file data into uint8array
          dispatch(updateProgressDialog('PROGRESS_STATUS_WRITE_CUSTOM_XML'))
          // Set custom xml to default xml, if it exists
          fileData = await resetCustomXml(fileData)

          // Upload file to have SharePoint populate/add custom xml
          console.info('upload: saving document for custom xml', url, path, fileName)
          const result = await client.uploadFile(url, path, fileName, fileData)
          const serverRelativeUrl = result.ServerRelativeUrl

          // Give SharePoint some time to process the file
          await wait(config.waitForSaveTimeout)

          // Read the file data back from SharePoint.
          fileData = await client.readFile(serverRelativeUrl)

          // Now we can populate the custom xml with the properties from the UI
          fileData = await writeCustomXml(fileData, xmlProperties)
        } else if (isOutlook()) {
          fileData = await readCurrentMailFile(userData.graphAccessToken)
        } else {
          console.info('not office and not outlook -> assuming browser')
          fileData = stringToUint('This is a testfile')
        }

        console.info('upload: saving document', url, path, fileName)
        dispatch(updateProgressDialog('PROGRESS_STATUS_UPLOAD_FILE'))

        const uploadResult = await client.uploadFile(url, path, fileName, fileData)

        console.info('upload: file saved successfully')

        if (typeof onSaveDone === 'function') onSaveDone()

        try {
          // Signal to close the currently opened document
          await setDocumentProperty('PDSCloseDocument', 1)
        } catch (e) {
          console.warn('failed to set document property', e)
        }

        if (isOffice()) {
          dispatch(updateProgressDialog('PROGRESS_STATUS_OPEN_FILE'))
          openDocument(buildFileItemUrl(userData.sc_root, uploadResult))
        } else if (isOutlook()) {
          setPDSCategory()
          const item = await client.getItem(uploadResult.ListItemAllFields.__deferred.uri)
          const itemUrl = item.__metadata.uri
          const fields = getEmailMetadataReadMode()
          await client.updateEmailContentType(fields, itemUrl)
        }

        const meta = {
          type: siteItem.type,
          id: siteItem.id,
          path,
          fileName,
          url: encodeURI(`${url}/${path}/${fileName}`)
        }
        try {
          await api.addFileSavedEvent(meta, getUserData(store))
        } catch (e) {
          console.warn('failed to add file saved event', e)
        }

        setTimeout(() => dispatch(endProgressDialog()), 1000)
      } catch (e) {
        dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_SAVE,
          errorMessage: e.message
        })
      }
    }
  }

  /**
   * Called from read mode in outlook when saving attachments.
   * siteItem: item picked in top tree, e.g. contact, project, intranet, department
   * fileItem: file node picked in the bottom tree
   * onSaveDone: call this function when save is done
   */
  function uploadFileAttachments (siteItem, fileItem, onSaveDone) {
    const state = store.getState()
    const userData = state.login.userData

    return function (dispatch) {
      dispatch(
        beginProgressDialog(
          'PROGRESS_TITLE_UPLOAD_ATTACHMENTS',
          'PROGRESS_STATUS_READ_ATTACHMENTS'
        )
      )

      readFileAttachments((err, attachments) => {
        if (err) {
          return dispatch({
            type: ERROR_ACTION,
            errorType: ERROR_TYPE_FILE_READ_ATTACHMENTS,
            errorMessage: err.message
          })
        }

        const client = SharePointAPIClient({
          scRoot: userData.sc_root,
          token: userData.accessToken
        })
        const url = getItemUrl(siteItem)
        const path = fileItem.path

        eachSeries(attachments, (attachment, next) => {
          const { fileData } = attachment
          const fileName = cleanFileName(attachment.fileName)
          console.info('upload: saving attachment', url, path, fileName)
          dispatch(updateProgressDialog(
            'PROGRESS_STATUS_UPLOAD_ATTACHMENTS',
            fileName
          ))
          client.uploadFile(url, path, fileName, fileData, next)
        }, (err) => {
          if (err) {
            return dispatch({
              type: ERROR_ACTION,
              errorType: ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS,
              errorMessage: err.message
            })
          }

          if (typeof onSaveDone === 'function') {
            onSaveDone()
          }

          eachSeries(attachments, (attachment, next) => {
            const { fileName } = attachment
            const meta = {
              type: siteItem.type,
              id: siteItem.id,
              path,
              fileName,
              url: encodeURI(`${url}/${path}/${fileName}`)
            }
            api.addFileSavedEvent(meta, getUserData(store)).then(eventResult => {
              next()
            }).catch(next)
          }, (err) => {
            if (err) {
              dispatch({
                type: ERROR_ACTION,
                errorType: ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS,
                errorMessage: err.message
              })
            } else {
              console.info('Successfully uploaded attachments')
            }
            dispatch(endProgressDialog())
          })
        })
      })
    }
  }

  /**
   * Called from compose mode in outlook.
   *
   * Saves the email -> So the email will get an itemId
   * Reads back the email data (retries until success)
   * Sends the email using the Graph API
   * Files the email in the selected folder
   * Closes the mail window (doesn't work when in inline mode)
   */
  function sendAndFile (siteItem, fileItem) {
    const state = store.getState()
    const userData = state.login.userData
    const graphToken = userData.graphAccessToken

    // TODO we need some sort of progress dialog here
    // since the user doesn't know anything of what is going on

    function readEmailWithRetries (itemId, cb) {
      let retries = config.outlook.readRetryCount
      const retryTimeout = config.outlook.readRetryTimeout

      async function tryReadEmail () {
        let fileData = null

        try {
          fileData = await readMailFile(itemId, graphToken)
        } catch (err) {
          if (--retries > 0) {
            console.info('Failed to read draft email,', retries, 'retries left')
            setTimeout(tryReadEmail, retryTimeout)
          } else {
            console.error('Failed to read draft email', err)
            cb(err)
          }
          return
        }

        console.info('Read draft email successfully')
        cb(null, fileData)
      }

      setTimeout(tryReadEmail, retryTimeout)
    }

    return async function (dispatch) {
      if (!isOutlookComposeMode()) {
        console.warn('Can only call sendAndFile() in outlook compose mode -> no op')
        return
      }

      if (siteItem.type === 'project') {
        let subject = await getEmailSubjectComposeMode()
        if (!subject.match(`(pds-p${siteItem.id})`)) {
          subject = subject + ` (pds-p${siteItem.id})`
          await setEmailSubjectComposeMode(subject)
        }
      }

      dispatch(beginProgressDialog(
        'PROGRESS_TITLE_SEND_AND_FILE',
        'PROGRESS_STATUS_SAVE_DRAFT_EMAIL'
      ))

      let itemId = null
      try {
        let retries = 5
        do {
          itemId = await saveDraftEmail()
          if (typeof itemId === 'string' && itemId.length > 0) {
            console.info('Got draft mail id', itemId)
            break
          } else if (--retries > 0) {
            console.warn('Retrying save draft email..')
            await wait(2000)
          } else {
            throw new Error('Failed to save draft email')
          }
        } while (true)
      } catch (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_EMAIL_SAVE_DRAFT,
          errorMessage: err.message
        })
      }

      dispatch(updateProgressDialog(
        'PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES'
      ))
      readEmailWithRetries(itemId, async (err, fileData) => {
        if (err) {
          return dispatch({
            type: ERROR_ACTION,
            errorType: ERROR_TYPE_EMAIL_READ,
            errorMessage: err.message
          })
        }

        console.info('Read draft email with length', fileData.length)

        dispatch(updateProgressDialog(
          'PROGRESS_STATUS_SEND_DRAFT_EMAIL'
        ))

        // NOTE: This must be done before sending the email since itemId
        // refers to the draft email, which is later removed once sent.
        const fields = await readMailMetadata(itemId, graphToken)
        fields.sender = await getEmailAddressComposeMode()

        try {
          await sendMail(itemId, graphToken)
        } catch (err) {
          return dispatch({
            type: ERROR_ACTION,
            errorType: ERROR_TYPE_EMAIL_SEND,
            errorMessage: err.message
          })
        }

        const client = SharePointAPIClient({
          scRoot: userData.sc_root,
          token: userData.accessToken
        })

        const siteUrl = getItemUrl(siteItem)
        const path = fileItem.path
        const fileName = encodeURI(cleanFileName(await emailFileNameOutlookComposeMode()))
        console.info('upload: saving document on', siteUrl, path, fileName)

        dispatch(updateProgressDialog(
          'PROGRESS_STATUS_UPLOAD_EMAIL'
        ))

        let uploadResult = null
        try {
          uploadResult = await client.uploadFile(siteUrl, path, fileName, fileData)
        } catch (err) {
          return dispatch({
            type: ERROR_ACTION,
            errorType: ERROR_TYPE_FILE_UPLOAD,
            errorMessage: err.message
          })
        }

        if (uploadResult) {
          const item = await client.getItem(uploadResult.ListItemAllFields.__deferred.uri)
          const itemUrl = item.__metadata.uri
          await client.updateEmailContentType(fields, itemUrl)
        }

        const meta = {
          type: siteItem.type,
          id: siteItem.id,
          path,
          fileName,
          url: encodeURI(`${siteUrl}/${path}/${fileName}`)
        }
        try {
          await api.addFileSavedEvent(meta, getUserData(store))
        } catch (e) {
          console.warn('failed to add file saved event', e)
        }

        dispatch(endProgressDialog())
        closeOutlookWindow()
      })
    }
  }

  return {
    uploadFile,
    uploadFileAttachments,
    sendAndFile
  }
}
