import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core'
import TreeView from './TreeView.js'
import { getItemIcon } from '../icons/index.js'
import redux from '../redux/index.js'
import { isSelected, sortFileItems } from '../util.js'
import { isOutlook } from '../lib/office.js'
import { CreateFolderIcon } from '../icons/file-icons.js'

const FileView = ({
  strings,
  selected,
  selectedFileNode,
  expand,
  items,
  page,
  showFolderDialog,
  onSetSelectedFolder,
  onGetRootFolder,
  onGetSubFolders,
  onSetSelectedFile,
  searchValue,
  searchResults,
  isSearching,
  showSearchResults,
  onSearchValueChange,
  onStartSearch,
  onResetSearch
}) => {
  const mount = useRef(false)
  useEffect(() => {
    if (isOutlook()) {
      if (!mount.current || mount.current !== selected.current?.id) {
        const subFolderFound = items[0]?.children?.find(({ path }) => path === 'files/E-post' || path === 'files/E-mail' || path === 'files/e-post' || path === 'files/email')
        if (subFolderFound) {
          onSetSelectedFolder(subFolderFound)
          mount.current = selected.current.id
          if (!expand[subFolderFound.id]) {
            onGetSubFolders(selected.current, subFolderFound)
          }
        }
      }
    }
  }, [onSetSelectedFolder, onGetSubFolders, selected.current, items])

  const renderCreateFolder = (item) => {
    return (item.type === 'folder' || item.type === 'rootfolder') && page !== 'templates' && (
      <Tooltip title={strings.create_folder} arrow>
        <ListItemIcon
          onClick={(e) => {
            if (expand[item.id]) {
              e.stopPropagation()
            }
            showFolderDialog(item)
          }} style={{ minWidth: 18 }}
        >
          <CreateFolderIcon width={24} height={24} />
        </ListItemIcon>
      </Tooltip>)
  }

  const onSetSelectedItem = (fileItem) => {
    if (fileItem.type === 'rootfolder') {
      onSetSelectedFolder(fileItem)
      onGetRootFolder(selected.current)
    } else if (fileItem.type === 'folder') {
      onSetSelectedFolder(fileItem)
      if (!expand[fileItem.id]) {
        onGetSubFolders(selected.current, fileItem)
      }
    } else if (fileItem.type === 'file') {
      onSetSelectedFile(fileItem)
    }
  }
  if (showSearchResults) {
    return (
      <ListView
        items={searchResults}
        isSearching={isSearching}
        strings={strings}
        sortItems={sortFileItems}
        isSelected={isSelected(selectedFileNode)}
        onItemSelected={onSetSelectedItem}
        onClose={onResetSearch}
      />
    )
  } else {
    return (
      <TreeView
        items={items}
        expand={expand}
        renderCreateFolder={renderCreateFolder}
        sortItems={sortFileItems}
        isSelected={isSelected(selectedFileNode)}
        onItemSelected={onSetSelectedItem}
      />
    )
  }
}

const ListView = ({
  items,
  strings,
  isSearching,
  onItemSelected,
  isSelected,
  sortItems,
  onClose
}) => {
  const renderItem = (item) => {
    const ItemIcon = getItemIcon(item)
    const primary = (<span style={listItemTextStyle}>{item.name}</span>)

    return (
      <div key={item.id}>
        <ListItem
          onClick={() => onItemSelected(item)}
          button
          selected={isSelected(item)}
          style={listItemStyle}
        >
          <ListItemIcon>
            <ItemIcon />
          </ListItemIcon>
          <ListItemText
            primary={primary}
          />
        </ListItem>
      </div>
    )
  }

  const renderItems = () => {
    if (!items.length && !isSearching) {
      return (
        <ListItem style={listItemStyle}>
          <ListItemText primary={strings.file_search_no_results} />
        </ListItem>
      )
    } else {
      return sortItems(items).map(renderItem)
    }
  }

  return (
    <List style={{ padding: 0 }}>
      {renderItems()}
    </List>
  )
}

const listItemStyle = {
  paddingLeft: 10,
  paddingRight: 0,
  paddingTop: 2,
  paddingBottom: 2
}

const listItemTextStyle = {
  marginLeft: -20,
  fontSize: '0.7rem'
}

const mapStateToProps = (state) => {
  const {
    selectedFileNode,
    trees,
    searchValue,
    searchResults,
    showSearchResults,
    isSearching
  } = state.files

  const getFileMeta = (item) => {
    const DEFAULT = { expand: {}, items: [] }
    if (item) {
      return trees[`${item.type}:${item.id}`] || DEFAULT
    } else {
      return DEFAULT
    }
  }

  const { expand, items } = getFileMeta(state.selected.current)

  return {
    strings: state.locale.strings,
    selectedFileNode,
    selected: state.selected,
    expand,
    items,
    page: state.page,
    searchValue,
    searchResults,
    showSearchResults,
    isSearching
  }
}

const mapDispatchToProps = (dispatch) => {
  const { files, dialog } = redux.actions
  return {
    showFolderDialog: (item) => {
      dispatch(dialog.showFolderDialog(item))
    },
    onSetSelectedFolder: (folderItem) => {
      dispatch(files.setSelectedFolder(folderItem))
    },
    onGetRootFolder: (siteItem) => {
      dispatch(files.getRootFolder(siteItem))
    },
    onGetSubFolders: (siteItem, folderItem) => {
      dispatch(files.getSubFolder(siteItem, folderItem))
    },
    onSetSelectedFile: (fileItem) => {
      dispatch(files.setSelectedFile(fileItem))
    },
    onSearchValueChange: (value) => {
      dispatch(files.setSearchValue(value))
    },
    onStartSearch: (current, searchValue) => {
      dispatch(files.searchFiles(current, searchValue))
    },
    onResetSearch: () => {
      dispatch(files.resetSearch())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileView)
