import React, { useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CircularProgress, TextField } from '@material-ui/core'

const AutoComplete = ({
  label,
  value,
  options,
  getData,
  onChange,
  locale
}) => {
  const [open, setOpen] = React.useState(false)
  const [search, setSearch] = React.useState(value)
  const [loading, setLoading] = React.useState(false)

  const onChangeValue = (str) => {
    if (typeof str === 'string') {
      onChange(str)
      setSearch(str)
    }
  }
  const onChangeText = (str) => setSearch(str)

  useEffect(() => {
    if (typeof getData === 'function') {
      setLoading(true)
      getData(search.length >= 2 ? search : undefined)
    }
  }, [search, getData])

  useEffect(() => setLoading(false), [options])

  return (
    <Autocomplete
      style={{ width: '100%' }}
      open={open}
      inputValue={search}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(e, value) => onChangeValue(value)}
      options={options}
      loading={loading}
      loadingText={locale.loading}
      noOptionsText={locale.no_options}
      closeText={locale.close}
      openText={locale.open}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          style={{ background: 'white' }}
          margin='dense'
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          onChange={(event) => onChangeText(event.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default AutoComplete
