import React from 'react'
import { TextField as MuiTextField } from '@material-ui/core'

/**
 * Wrapper around TextField with common props set
 */
const TextField = (props) => {
  return (
    <MuiTextField
      margin='dense'
      variant='outlined'
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{ inputProps: { autoCapitalize: 'off', autoComplete: 'off', autoCorrect: 'off', spellCheck: 'false' } }}
      {...props}
    >
      {props.children}
    </MuiTextField>
  )
}

export default TextField
