import { SharePointAPIClient } from '@pergas-pds/pds-sp-client'
import { fromByteArray } from 'base64-js'
import parseUrl from 'url-parse'
import { getUserData } from '../common.js'
import { setSelectedItem } from '../selected/actions.js'
import {
  beginProgressDialog,
  updateProgressDialog,
  endProgressDialog
} from '../progress/actions.js'
import {
  isOfficeZipFile,
  buildFileItemUrl,
  getItemUrl,
  getItemRoot
} from '../../util'
import {
  addFileAttachmentFromBase64,
  clearCustomXml
} from '../../lib/office.js'

import { ERROR_ACTION } from '../error/actions.js'
import { hideDialog } from '../dialog/actions.js'

const ERROR_TYPE_FILE_READ = 'ERROR_TYPE_FILE_READ'
const ERROR_TYPE_DOC_LIB_GET = 'ERROR_TYPE_DOC_LIB_GET'
const ERROR_TYPE_FILE_ATTACH = 'ERROR_TYPE_FILE_ATTACH'
const ERROR_TYPE_FILE_SEARCH = 'ERROR_TYPE_FILE_SEARCH'
const ERROR_TYPE_FOLDER_CREATED_ADD = 'ERROR_TYPE_FOLDER_CREATED_ADD'

export const FILES_SET_SELECTED_FOLDER_ACTION = 'FILES_SET_SELECTED_FOLDER_ACTION'
export const FILES_SET_SELECTED_FILE_ACTION = 'FILES_SET_SELECTED_FILE_ACTION'
export const FILES_RESET_SELECTED_FILE_ACTION = 'FILES_RESET_SELECTED_FILE_ACTION'
export const FILES_GET_ROOT_FOLDER_ACTION = 'FILES_GET_ROOT_FOLDER_ACTION'
export const FILES_GET_SUB_FOLDER_ACTION = 'FILES_GET_SUB_FOLDER_ACTION'
export const FILES_SET_SEARCH_VALUE_ACTION = 'FILES_SET_SEARCH_VALUE_ACTION'
export const FILES_GOT_SEARCH_RESULTS_ACTION = 'FILES_GOT_SEARCH_RESULTS_ACTION'
export const FILES_BEGIN_SEARCH_ACTION = 'FILES_BEGIN_SEARCH_ACTION'
export const FILES_END_SEARCH_ACTION = 'FILES_END_SEARCH_ACTION'
export const FILES_RESET_SEARCH_ACTION = 'FILES_RESET_SEARCH_ACTION'

export function setSelectedFolder (folder) {
  return {
    type: FILES_SET_SELECTED_FOLDER_ACTION,
    folder
  }
}

export function setSelectedFile (file) {
  return {
    type: FILES_SET_SELECTED_FILE_ACTION,
    file
  }
}

export function resetSelectedFile () {
  return {
    type: FILES_RESET_SELECTED_FILE_ACTION
  }
}

export function setSearchValue (searchValue) {
  return {
    type: FILES_SET_SEARCH_VALUE_ACTION,
    searchValue
  }
}

export function resetSearch () {
  return {
    type: FILES_RESET_SEARCH_ACTION
  }
}

export function initFileActions (store) {
  /**
   * Get files and folders from templates
   */
  function getTemplatesRootFolder () {
    const userData = getUserData(store)
    const state = store.getState()
    const item = {
      type: 'templates',
      id: 0,
      url: `${userData.sc_root}/admin`,
      name: state.locale.strings.templates_root_node
    }
    return function (dispatch) {
      dispatch(setSelectedItem(item))
      dispatch(getRootFolder(item))
    }
  }

  /**
   * Get files and folders for a root folder item
   */
  function getRootFolder (siteItem) {
    return function (dispatch) {
      const url = getItemUrl(siteItem)
      const path = getItemRoot(siteItem)
      getFilesHelper(url, path, (err, items) => {
        if (err) {
          dispatch(documentLibraryError(err))
        } else {
          dispatch({
            type: FILES_GET_ROOT_FOLDER_ACTION,
            siteItem,
            path,
            items
          })
        }
      })
    }
  }

  /**
   * Get files and folders from a sub folder
   */
  function getSubFolder (item, folderItem) {
    return function (dispatch) {
      const url = getItemUrl(item)
      const { path } = folderItem
      getFilesHelper(url, path, (err, items) => {
        if (err) {
          dispatch(documentLibraryError(err))
        } else {
          dispatch({
            type: FILES_GET_SUB_FOLDER_ACTION,
            item,
            parent: folderItem,
            items
          })
        }
      })
    }
  }

  function getFilesHelper (url, path, cb) {
    const userData = getUserData(store)
    const client = SharePointAPIClient({
      scRoot: userData.sc_root,
      token: userData.accessToken
    })

    client.getDocumentLibraryFiles(url, path, (err, result) => {
      if (err) {
        return cb(err)
      }

      const folders = result.Folders.results.filter(folder => {
        return folder.Name !== 'Forms'
      }).map(folder => {
        return {
          type: 'folder',
          children: [],
          id: folder.UniqueId,
          name: folder.Name,
          title: folder.Title,
          itemCount: folder.ItemCount,
          created: folder.TimeCreated,
          modified: folder.TimeLastModified,
          path: `${path}/${folder.Name}`
        }
      }).sort(compareFilenames)

      const files = result.Files.results.filter(file => {
        const length = parseInt(file.Length, 10)
        return !isNaN(length) && length > 0
      }).map(file => {
        return {
          type: 'file',
          id: file.UniqueId,
          name: file.Name,
          title: file.Title,
          created: file.TimeCreated,
          modified: file.TimeLastModified,
          path,
          uri: buildFileItemUrl(userData.sc_root, file),
          serverRelativeUrl: file.ServerRelativeUrl
        }
      }).sort(compareFilenames)

      cb(null, folders.concat(files))
    })
  }

  /**
   * Reads a file from SharePoint and attaches it to an email.
   * Can only be called during compose mode in Outlook.
   */
  function attachFile (serverRelativeUrl, name) {
    return function (dispatch) {
      console.info('dispatching attachFile', serverRelativeUrl, name)

      const userData = getUserData(store)
      const client = SharePointAPIClient({
        scRoot: userData.sc_root,
        token: userData.accessToken
      })

      dispatch(
        beginProgressDialog(
          'PROGRESS_TITLE_ATTACH_FILE',
          'PROGRESS_STATUS_READ_SHAREPOINT_FILE'
        )
      )

      client.readFile(serverRelativeUrl, async (err, data) => {
        if (err) {
          return dispatch({
            type: ERROR_ACTION,
            errorType: ERROR_TYPE_FILE_READ,
            errorMessage: err.message
          })
        }

        dispatch(updateProgressDialog('PROGRESS_STATUS_ATTACH_FILE'))

        if (isOfficeZipFile(name)) {
          console.info('clearing custom xml from', name)
          try {
            data = await clearCustomXml(data)
          } catch (err) {
            return dispatch({
              type: ERROR_ACTION,
              errorType: ERROR_TYPE_FILE_ATTACH,
              errorMessage: err.message
            })
          }
        }

        const base64 = fromByteArray(data)
        addFileAttachmentFromBase64(base64, name, (err, id) => {
          if (err) {
            return dispatch({
              type: ERROR_ACTION,
              errorType: ERROR_TYPE_FILE_ATTACH,
              errorMessage: err.message
            })
          } else {
            setTimeout(() => dispatch(endProgressDialog()), 1000)
            console.info('successfully added file attachment', serverRelativeUrl, name, id)
          }
        })
      })
    }
  }

  function getServerRelativeUrl (scRoot, item) {
    if (item.type === 'contact') {
      return `${parseUrl(scRoot).pathname}/customer/${item.id}/files`
    } else if (item.type === 'project') {
      return `${parseUrl(scRoot).pathname}/project/${item.id}/files`
    } else if (item.type === 'department') {
      return `${parseUrl(scRoot).pathname}/${item.id}/files`
    } else if (item.type === 'category') {
      return `${parseUrl(scRoot).pathname}/${item.collection_id}/${item.id}`
    } else if (item.type === 'templates') {
      return `${parseUrl(scRoot).pathname}/admin/pdsMallar`
    }
  }

  function createFolders (siteItem, item, folders = []) {
    return async function (dispatch) {
      try {
        const userData = getUserData(store)
        const scRoot = userData.sc_root
        const url = getItemUrl(siteItem)
        let relativeUrl = getServerRelativeUrl(scRoot, siteItem)

        if (item.type === 'folder') {
          relativeUrl = `${relativeUrl.replace('files', item.path)}`
        }

        for (const folder of folders) {
          const options = {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
              'Content-Type': 'application/json;odata=verbose'
            },
            body: JSON.stringify({
              __metadata: {
                type: 'SP.Folder'
              },
              ServerRelativeUrl: `${relativeUrl}/${folder}`
            })
          }

          const response = await fetch(`${url}/_api/web/folders`, options)
          if (response.status !== 201) {
            throw new Error('ERROR_TYPE_FOLDER_CREATED_ADD')
          }
        }

        dispatch(getSubFolder(siteItem, item))
        dispatch(hideDialog())
      } catch (e) {
        dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FOLDER_CREATED_ADD
        })
      }
    }
  }
  /**
   * Search files in currently selected context.
   */
  function searchFiles (siteItem, query) {
    return function (dispatch) {
      const userData = getUserData(store)
      const scRoot = userData.sc_root

      const url = getItemUrl(siteItem)
      const serverRelativeUrl = getServerRelativeUrl(scRoot, siteItem)
      const client = SharePointAPIClient({
        scRoot,
        token: userData.accessToken
      })

      console.info('searching files in', siteItem, url, serverRelativeUrl, query)

      dispatch({ type: FILES_BEGIN_SEARCH_ACTION })

      const emitter = client.searchDocumentLibrary(url, serverRelativeUrl, query)
      emitter.on('results', results => {
        results = results.filter(file => {
          const length = parseInt(file.Length, 10)
          return !isNaN(length) && length > 0
        }).map(file => {
          return {
            type: 'file',
            id: file.UniqueId,
            name: file.Name,
            title: file.Title,
            created: file.TimeCreated,
            modified: file.TimeLastModified,
            uri: buildFileItemUrl(scRoot, file),
            serverRelativeUrl: file.ServerRelativeUrl
          }
        })
        dispatch({ type: FILES_GOT_SEARCH_RESULTS_ACTION, results })
      })
      emitter.on('end', () => {
        dispatch({ type: FILES_END_SEARCH_ACTION })
      })
      emitter.on('error', err => {
        dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_SEARCH,
          errorMessage: err.message
        })
      })
      emitter.on('fetch-url', url => {
        console.log('file search: fetching from url', url)
      })
    }
  }

  return {
    createFolders,
    getRootFolder,
    getTemplatesRootFolder,
    getSubFolder,
    attachFile,
    searchFiles
  }
}

function documentLibraryError (err) {
  console.error('documentLibraryerror', err)
  // TODO if error code is 401, then reload page
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_DOC_LIB_GET,
    errorMessage: err.message
  }
}

function compareFilenames (_lhs, _rhs) {
  const lhs = _lhs.name.toLowerCase()
  const rhs = _rhs.name.toLowerCase()
  if (lhs < rhs) return -1
  if (lhs === rhs) return 0
  if (lhs > rhs) return 1
}
