import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import redux from './redux'

import ContactPage from './pages/ContactPage.js'
import ProjectPage from './pages/ProjectPage.js'
import IntranetPage from './pages/IntranetPage.js'
import TemplatesPage from './pages/TemplatePage.js'

import ProgressDialog from './dialogs/ProgressDialog.js'
import PeopleDialog from './dialogs/PeopleDialog.js'
import SaveDialog from './dialogs/SaveDialog.js'
import SettingsDialog from './dialogs/SettingsDialog.js'
import { readDocumentProperty } from './lib/office.js'
import FolderDialog from './dialogs/FolderDialog'

const getDialog = (dialog) => {
  switch (dialog) {
    case 'people':
      return PeopleDialog
    case 'save':
      return SaveDialog
    case 'settings':
      return SettingsDialog
    case 'folders':
      return FolderDialog
    default:
      return null
  }
}

const WorkSpace = ({
  page,
  sharePointSaveEnabled,
  sharePointTemplatesEnabled,
  dialog,
  strings,
  shouldShowProgress
}) => {
  const dispatch = useDispatch()
  /*
   * If custom document properties are set, we force a route change.
  */
  const [documentPropertyId, setDocumentPropertyId] = useState(null)
  const [loadingTakeover, setLoadingTakeover] = useState(true)
  useEffect(() => {
    const documentProperties = async () => {
      try {
        const { value: pdsType } = await readDocumentProperty('pds_type')
        const { value: pdsId } = await readDocumentProperty('pds_id')
        setDocumentPropertyId(pdsId)
        if (pdsType === 'project') {
          dispatch(redux.actions.page.setCurrentPage('project'))
        } else if (pdsType === 'contact') {
          dispatch(redux.actions.page.setCurrentPage('contact'))
        }
      } catch (e) {
      } finally {
        setLoadingTakeover(false)
      }
    }
    documentProperties()
  }, [setDocumentPropertyId, setLoadingTakeover, dispatch])

  const getPage = () => {
    switch (page) {
      case 'contact':
        return ContactPage
      case 'project':
        return ProjectPage
      case 'intranet': {
        return sharePointSaveEnabled ? IntranetPage : ContactPage
      }
      case 'templates': {
        return sharePointTemplatesEnabled ? TemplatesPage : ContactPage
      }
      default:
        return ContactPage
    }
  }

  const Page = getPage()
  const Dialog = getDialog(dialog)

  const renderProgress = () => {
    return shouldShowProgress ? <ProgressDialog /> : null
  }

  const renderPage = () => Page ? <Page pdsId={documentPropertyId} /> : null
  const renderDialog = () => Dialog ? <Dialog /> : null

  return (
    <>
      {renderProgress()}
      {renderDialog()}
      {loadingTakeover ? <ProgressDialog /> : renderPage()}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    dialog: state.dialog.name,
    strings: state.locale.strings,
    shouldShowProgress: state.progress.show,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    sharePointTemplatesEnabled: state.login.userData.sharePointTemplatesEnabled
  }
}

export default connect(mapStateToProps)(WorkSpace)
