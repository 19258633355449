import React from 'react'
import { connect } from 'react-redux'
import AutoComplete from './AutoComplete.js'
import redux from '../../redux/index.js'

const Choice = ({
  contentType,
  properties,
  onSetProperty,
  locale,
  style
}) => {
  const key = contentType.field_internal_name
  const value = properties[key] || ''
  const label = `${contentType.field_title}${contentType.ui_required ? ' *' : ''}`
  const blob = contentType.field_blob
  const options = blob.Choices.results.slice().sort()
  const onChange = (value) => onSetProperty(key, value)

  return (
    <div style={style}>
      <AutoComplete
        label={label}
        value={value}
        options={options}
        onChange={onChange}
        locale={locale}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    properties: state.save.documentProperties,
    locale: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { save } = redux.actions
  return {
    onSetProperty: (key, value) => {
      dispatch(save.setProperty(key, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Choice)
