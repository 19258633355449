import createReduxStore from './create-store.js'
import createClient from '@pergas-pds/pds-api-client'
import { initCategoryActions } from './category/actions.js'
import { initContactActions } from './contact/actions.js'
import { initContentTypeActions } from './content-type/actions.js'
import {
  showSaveDialog,
  showSettingsDialog,
  showPeopleDialog,
  showFolderDialog,
  hideDialog
} from './dialog/actions.js'
import { initDepartmentActions } from './department/actions.js'
import { resetError } from './error/actions.js'
import {
  setSelectedFolder,
  setSelectedFile,
  resetSelectedFile,
  setSearchValue,
  resetSearch,
  initFileActions
} from './files/actions.js'
import { changeLocale } from './locale/actions.js'
import { initLoginActions } from './login/actions.js'
import { setCurrentPage } from './page/actions.js'
import { initProjectActions } from './project/actions.js'
import {
  beginProgressDialog,
  updateProgressDialog,
  endProgressDialog
} from './progress/actions.js'
import {
  setSaveDialogFileName,
  setProperty,
  readDocumentProperties,
  initFileUploadActions
} from './save/actions.js'
import {
  setSelectedItem,
  resetSelectedItem
} from './selected/actions.js'
import config from '../config.js'

const store = createReduxStore()
const api = createClient(config.api)
const {
  createFolders,
  getRootFolder,
  getTemplatesRootFolder,
  getSubFolder,
  attachFile,
  searchFiles
} = initFileActions(store)

const {
  uploadFile,
  uploadFileAttachments,
  sendAndFile
} = initFileUploadActions(api, store)

const redux = {
  store,
  actions: {
    category: initCategoryActions(api, store),
    contact: initContactActions(api, store),
    contentType: initContentTypeActions(api, store),
    department: initDepartmentActions(api, store),
    dialog: {
      showSaveDialog,
      showSettingsDialog,
      showPeopleDialog,
      showFolderDialog,
      hideDialog
    },
    error: { resetError },
    files: {
      setSelectedFolder,
      setSelectedFile,
      resetSelectedFile,
      setSearchValue,
      resetSearch,
      createFolders,
      getRootFolder,
      getTemplatesRootFolder,
      getSubFolder,
      attachFile,
      searchFiles
    },
    locale: { changeLocale },
    login: initLoginActions(api, store),
    page: { setCurrentPage },
    project: initProjectActions(api, store),
    progress: {
      beginProgressDialog,
      updateProgressDialog,
      endProgressDialog
    },
    save: {
      setSaveDialogFileName,
      setProperty,
      readDocumentProperties,
      uploadFile,
      uploadFileAttachments,
      sendAndFile
    },
    selected: {
      resetSelectedItem,
      setSelectedItem
    }
  }
}

export default redux
