import React from 'react'
import { connect } from 'react-redux'
import AutoComplete from './AutoComplete.js'
import redux from '../../redux/index.js'

const ContactChoice = ({
  contentType,
  properties,
  contacts,
  getContacts,
  onSetProperty,
  locale,
  style
}) => {
  const key = contentType.field_internal_name
  const value = properties[key] || ''
  const label = `${contentType.field_title}${contentType.ui_required ? ' *' : ''}`
  const onChange = (value) => onSetProperty(key, value)

  return (
    <div style={style}>
      <AutoComplete
        label={label}
        value={value}
        options={contacts.map(c => c.name)}
        getData={getContacts}
        onChange={onChange}
        locale={locale}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    properties: state.save.documentProperties,
    locale: state.locale.strings,
    contacts: state.contact.items
  }
}

const mapDispatchToProps = (dispatch) => {
  const { contact, save } = redux.actions
  return {
    onSetProperty: (key, value) => {
      dispatch(save.setProperty(key, value))
    },
    getContacts: (name) => {
      dispatch(contact.getContacts(name))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactChoice)
