import redux from './redux'
import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import './index.css'
import App from './App.js'
import msalClient from './lib/msal.js'
import { onReady, printDiagnostics } from './lib/office.js'
import storage from './redux/storage.js'
import { decodeToken, isValidToken } from './util.js'

// https://material-ui.com/customization/themes
const theme = createTheme({
  tooltip: {
    color: '#eeffff',
    backgroundColor: '#d57b00',
    fontSize: '16px',
    fontWeight: 'lighter',
    padding: '5px 15px 5px 15px',
    maxWidth: '600px',
    borderRadius: '3px',
    userSelect: 'text'
  },
  // https://v4.mui.com/customization/globals/#css
  overrides: {
    MuiSvgIcon: {
      root: {
        // Need smaller icons for toolbar
        fontSize: '1.3rem'
      }
    }
  }
})

const start = (idToken) => {
  printDiagnostics()

  const Main = () => {
    return (
      <Provider store={redux.store}>
        <MuiThemeProvider theme={theme}>
          <App idToken={idToken} />
        </MuiThemeProvider>
      </Provider>
    )
  }

  ReactDOM.render(
    <React.StrictMode>
      <Main />
    </React.StrictMode>,
    document.getElementById('root')
  )
}

function onValidIdToken (idToken) {
  onReady(() => start(idToken))
}

const { idToken, email } = redux.store.getState().login.userData

/**
 * Main login method to receive id token
 * 1. when the user hasn't logged in before, or
 * 2. when ssoSilent() fails
 */
function redirectLogin (msal) {
  msal.handleRedirectPromise().then((tokenResponse) => {
    if (tokenResponse !== null) {
      console.info('got id token from redirect method')
      onValidIdToken(tokenResponse.idToken)
    } else {
      try {
        console.info('initiating redirect method')
        msal.loginRedirect()
      } catch (err) {
        // TODO this is a fatal error -> rende Error page with details
        console.error('msal.loginRedirect failed')
      }
    }
  }).catch((err) => {
    // TODO this is a fatal error -> rende Error page with details
    console.error('msal.handleRedirectPromise failed', err)
  })
}

if (isValidToken(idToken)) {
  console.info('found cached valid id token')
  onValidIdToken(idToken)
} else {
  const msal = msalClient()
  const payload = decodeToken(idToken)
  const loginHint = payload.preferred_username || email

  if (typeof loginHint === 'string') {
    console.info('msal.ssoSilent() with login hint', loginHint)
    const silentRequest = { loginHint }
    msal.ssoSilent(silentRequest).then(result => {
      onValidIdToken(result.idToken)
    }).catch((err) => {
      console.error('msal.ssoSilent failed', err)
      storage.deleteUserData()
      redirectLogin(msal)
    })
  } else {
    redirectLogin(msal)
  }
}
